<template src="./RoleMemberDeleteModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "@/components/common/AppModal/AppModal.vue";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  props: {
    payload: {
      required: true,
    },
  },
  data: function () {
    return {
      visibleDialog: true,
      clubId: this.$route.params.id,
    };
  },
  computed: {
    modalVisible() {
      return this.$store.state.systemDashboard.deleteRoleMemberPopUpVisibility;
    },
  },
  methods: {
    cancelModal() {
      this.$store.commit("DELETE_ROLE_MEMBER_POP_UP_VISIBILITY", false);
    },
    deleteHandler() {
      ;

      const data = {
        clubId: this.payload.clubId,
        clubSystemUserId: this.payload.clubSystemUserId,
      };

      this.$store.dispatch("deleteUser", data);
      this.$store.commit("DELETE_ROLE_MEMBER_POP_UP_VISIBILITY", false);
    },
  },
};
</script>

<style></style>
