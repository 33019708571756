<AppSnackBar
  :message="showMsg"
  :notificationType="'success'"
  v-if="isClubUpdated"
></AppSnackBar>
<app-loader v-if="clubUpdateLoading"></app-loader>
<div class="dashboard-right-inner">
  <Breadcrumb />

  <div class="max-height-100--60 overflow-y-auto">
    <div class="dashboard-header box-shadow-none">
      <h2 class="font-28 font-34-sm-big font-w-300">Settings</h2>
      <p class="font-14 color-light2 m-0">
        Manage {{isClub()}} information and features
      </p>
    </div>

    <div v-if="isPlatformAdmin" class="dashboard-content p-0">
      <div class="d-flex flex-wrap bg-white box-shadow-2">
        <div
          :class=" activeTab == tab.name  ? 'btn-active' : ''"
          :ref="tab.name"
          v-for="tab in tabNames"
        >
          <kbutton
            :ref="tab.name"
            @click="switchTabHandlers(tab.name)"
            :theme-color="'primary'"
            :fill-mode="'flat'"
            :class="'radius-0 color-black'"
            >{{tab.value}}
          </kbutton>
        </div>
      </div>
      <component :is="activeTab"></component>
    </div>

    <div v-if="!isPlatformAdmin" class="dashboard-content p-0">
      <div class="d-flex flex-wrap bg-white box-shadow-2">
        <div
          :class=" activeTab == tab.name  ? 'btn-active' : ''"
          :ref="tab.name"
          v-for="tab in tabNamesForUser"
        >
          <kbutton
            :ref="tab.name"
            @click="switchTabHandlers(tab.name)"
            :theme-color="'primary'"
            :fill-mode="'flat'"
            :class="'radius-0 color-black'"
            >{{tab.value}}
          </kbutton>
        </div>
      </div>
      <component :is="activeTab"></component>
    </div>
  </div>
</div>
