<template src="./ClubSettings.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { Form } from "@progress/kendo-vue-form";
import { Upload } from "@progress/kendo-vue-upload";
import myUpload from "vue-image-crop-upload";
import club from "@/store/clubDetails/club";
// import { Avatar } from "@progress/kendo-vue-inputs";
import AppSnackBar from "../../../common/AppSnackBar/AppSnackBar";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import AppLoader from "../../../common/AppLoader/AppLoader";
import { HTTP } from "@/service";
import { updateClubService } from "../../../../services/clubDetails/club.service";
import { configuration } from "@/configurationProvider";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

export default {
  inject : ['hasPutPermission'],
  components: {
    kbutton: Button,
    "k-input": Input,
    "k-form": Form,
    upload: Upload,
    myUpload,
    // Avatar,
    dropdownlist: DropDownList,
    AppSnackBar,
    AppLoader,
  },
  data() {
    var url = configuration.apiBaseUrl;

    return {
      clubId: this.$route.params.id,
      url,
      clubLogoTempPath: "",
      uploadDocument: url + "/api/v1/Document?clubId=" + this.$route.params.id,
      isCorrectAbbriviation: true,
      imageProgress: false,
      allowedExtensions: [".png", ".jpeg", ".jpg"],
      validationErrors: "",
      profilePictureId: null,
      tempProfilePictureId: null,
      tempPhotoPath: "",
      notifyMessage: "Success",
      notifyType: "success",
      isNotify: false,
      loader: false,
      imgUploading: false,
      timeZoneValue: {},
      searchData: "",
    };
  },
  computed: {
    club() {
      return this.$store.getters.clubDetails;
    },
    isClubUpdated() {
      return this.$store.state.club.showMsg;
    },
    showMsg() {
      return this.$store.state.club.status.Message;
    },
    clubUpdateLoading() {
      return this.$store.state.club.loading;
    },
    errorMessages() {
      return this.$store.state.club.updateErrors;
    },
    timeZonesList() {
      return this.$store.state.commonEmailCampaign.timeZoneList;
    },
  },
  watch: {
    timeZonesList(newVal) {
      const timeZone = this.club?.timeZoneId;

      this.timeZoneValue = this.timeZonesList.find((item) => {
        return item.id === timeZone;
      });
    },
  },
  mounted() {
    this.$store.dispatch("getTimeZonesList", 999);
    this.getClub({
      clubId: this.clubId,
      loadMenu: false,
    });
    this.$store.commit("SET_UPDATE_ERRORS", []);
  },
  methods: {
    filterChange(event) {
      this.searchData = "";
      let data = this.$store.state.commonEmailCampaign.timeZoneTempList;
      this.searchData = filterBy(data, event.filter);
      this.$store.commit("SET_TIME_ZONE_LIST", this.searchData);
    },
    handleTimeZoneChangeValue(e) {
      this.timeZoneValue = e.value;
    },
    updateClub() {
      this.loader = true;
      this.club.logoId = this.club.logoId
        ? this.club.logoId
        : this.club.logo?.id;
      this.club.timeZoneId = this.timeZoneValue.id;
      updateClubService(club.state.club)
        .then((response) => {
          this.$store.dispatch("getClub", {
            clubId: this.clubId,
            loadMenu: false,
          });
          this.loader = false;
          this.showNotification(
            "Changes in " +
              this.isClub().toString().toLowerCase().trim() +
              " settings have been updated successfully.",
            "success"
          );
        })
        .catch((error) => {
          this.$store.commit("SET_UPDATE_ERRORS", error.response.data.errors);
          this.loader = false;
        });
    },

    getClub(clubId) {
      this.$store.dispatch("getClub", clubId);
    },
    removeDocument() {
      this.club.logo = "";
      this.club.logoId = "";
      this.tempPhotoPath = "";
    },
    onProgress() {
      this.imageProgress = true;
    },
    updateClubCancel() {
      this.$store.commit("SET_UPDATE_ERRORS", []);
      this.$router.push(`/club-details/${this.clubId}/dashboard`);
    },

    uploadProfilePhoto(e) {
      this.imgUploading = true;
      const image = e.target.files[0];

      if (image.type != "image/jpeg" && image.type != "image/png") {
        this.showNotification("File Type Not Supported.", "error");

        this.imgUploading = false;

        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.tempPhotoPath = e.target.result;
      };

      const bodyFormData = new FormData();
      bodyFormData.append("photoPath", image);

      HTTP.post(
        configuration.apiBaseUrl +
          "/api/v1/Document?clubId=" +
          this.$route.params.id,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then((response) => {
          this.imgUploading = false;
          this.club.logoId = response.data.id;
        })
        .catch((err) => {});
    },
    isClub() {
      return this.club.isAssociation ? "Association" : "Club";
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
  },
};
</script>
