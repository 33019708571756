<AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>
<AppLoader v-if="loader"></AppLoader>

<div class="">
    <!-- <h3 class="font-14 font-w-700 text-color">Feature Control</h3>
    <p class="m-0 font-12 text-color">Specify the features the club will have access to</p> -->

    <!-- <div class="blank-space-md border-bottom w-100"></div> -->

    <!-- <div class="p-30 p-0-vsm max-height-100-310 overflow-y-auto max-height-100-sm"> -->
    <div class="p-30 p-0-vsm mb-5">
        <div class="row width-890 m-0-vsm">
            <div class="blank-space-lg2"></div>

            <div class="col-md-5 col-12 mb-md-0 mb-sm-4 mb-4">
                <h3 class="font-14 font-w-700 text-color">Feature Settings</h3>
                <p class="m-0 font-12 text-color">Click on the button to enable or <br class="hide-vsm" /> disable features for the
                    {{isClub()}}</p>
            </div>

            <div class="col-md-7 col-12">
                <k-form>
                    <!-- <div class="d-flex flex-wrap flex-sm-nowrap border-bottom pb-16 mb-24">
                    <div class="me-0 me-sm-3 mb-2 mb-sm-0 w-100-vsm">
                        <k-switch :size="'small'" @change="digitalPassStatusChange" :checked="club.enablePass"/>
                    </div>

                    <div>
                        <h3 class="font-16">Events</h3>
                        <p class="font-14 color-light2 m-0">Includes all features related to event management</p>
                    </div>
                </div> -->

                    <!-- <div class="d-flex flex-wrap flex-sm-nowrap border-bottom pb-16 mb-24">
                    <div class="me-0 me-sm-3 mb-2 mb-sm-0 w-100-vsm">
                        <k-switch :size="'small'" @change="digitalPassStatusChange" :checked="club.enablePass"/>
                    </div>

                    <div>
                        <h3 class="font-16">Tasks</h3>
                        <p class="font-14 color-light2 m-0">Includes all features to task management</p>
                    </div>
                </div> -->
                    <!-- 
                <div class="d-flex flex-wrap flex-sm-nowrap border-bottom pb-16 mb-24">
                    <div class="me-0 me-sm-3 mb-2 mb-sm-0 w-100-vsm">
                        <k-switch :size="'small'" @change="digitalPassStatusChange" :checked="club.enablePass"/>
                    </div>

                    <div>
                        <h3 class="font-16">Messages</h3>
                        <p class="font-14 color-light2 m-0">Includes all features related to messaging</p>
                    </div>
                </div> -->

                    <div class="d-flex flex-wrap flex-sm-nowrap border-bottom pb-16 mb-24">
                        <div class="me-0 me-sm-3 mb-2 mb-sm-0 w-100-vsm">
                            <k-switch :size="'small'" @change="digitalPassStatusChange" :checked="changeEnablePass" />
                        </div>

                        <div>
                            <h3 class="font-16">Digital Pass</h3>
                            <p class="font-14 color-light2 m-0">Includes features related to member's digital pass.</p>
                        </div>

                    </div>
                    <div class="d-flex flex-wrap flex-sm-nowrap border-bottom pb-16 mb-24">
                        <div class="me-0 me-sm-3 mb-2 mb-sm-0 w-100-vsm">
                            <k-switch :size="'small'" @change="voucherStatusChange" :checked="changeEnableVoucher" />
                        </div>


                        <div>
                            <h3 class="font-16">Voucher</h3>
                            <p class="font-14 color-light2 m-0">Includes feature for voucher generation for audience.
                            </p>
                        </div>
                    </div>

                    <div class="d-flex flex-wrap flex-sm-nowrap border-bottom pb-16 mb-24">
                        <div class="me-0 me-sm-3 mb-2 mb-sm-0 w-100-vsm">
                            <k-switch :size="'small'" @change="emailCampaignStatusChange"
                                :checked="changeEnableEmailCampaign" />
                        </div>
                        <div>
                            <h3 class="font-16">Email Campaign</h3>
                            <p class="font-14 color-light2 m-0">Includes feature related to marketing campaigns,
                                segments, and automation.
                            </p>
                        </div>
                    </div>
                    <!-- 
                <div class="d-flex flex-wrap flex-sm-nowrap border-bottom pb-16 mb-24">
                    <div class="me-0 me-sm-3 mb-2 mb-sm-0 w-100-vsm">
                        <k-switch :size="'small'" @change="digitalPassStatusChange" :checked="club.enablePass"/>
                    </div>

                    <div>
                        <h3 class="font-16">Merchandise</h3>
                        <p class="font-14 color-light2 m-0">Includes features related to club's merchandise (e-commerce)</p>
                    </div>
                </div> -->

                </k-form>
            </div>
        </div>
    </div>


    <div class="bottom-fixed-button-wrap d-flex justify-content-between bg-white">
        <kbutton @click="updateClubCancel" :class="'flex-none'">Cancel</kbutton>
        <kbutton :theme-color="'primary'" @click="save" :class="'flex-none'">Save Changes</kbutton>
    </div>

</div>