<modal :class="'max-height-100-145'" title="Add New App" :isLoading="addUserLoading" :handler="true"
  @modalHandler="this.modalHandler" :cssClass="'modal-md-2'">
  <template v-slot:content>
    <div class="pt-4 px-2 pb-4">
      <div class="row">
        <div class="col-sm-12">
          <div class="k-form-field text-start">
            <label>Select the platform you want to connect:</label>
            <dropdownlist :disabled="this.fromUpdate" :data-items="this.platformItems" :text-field="'text'"
              :data-item-key="'id'" :class="'mb-24'" v-model="this.selectedPlatform"
              @change="this.platformChangeHandler">
            </dropdownlist>
          </div>
        </div>
        <div v-if="this.selectedPlatform?.id == 1">
          <div class="col-sm-12">
            <p class="font-14 font-w-700 mb-12">
              Please enter your platform's client ID and secret below:
            </p>
          </div>
          <div class="col-sm-12">
            <div class="k-form-field text-start">
              <label>Client ID</label>
              <k-input :placeholder="'127nghh565dhfwdf4d5565'" v-model="this.appModel.exsited.clientId" />
              <div class="error-msg">{{errorMesseges?.clientId}}</div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="k-form-field text-start">
              <label>Client Secret</label>
              <k-input type="password" :placeholder="'****'" v-model="this.appModel.exsited.clientSecret" />
              <div class="error-msg">{{errorMesseges?.clientSecret}}</div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="k-form-field text-start">
              <label>Redirect Uri</label>
              <k-input :placeholder="'Redirect Uri'" v-model="this.appModel.exsited.redirectUri" />
              <div class="error-msg">{{errorMesseges?.redirectUri}}</div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="k-form-field text-start">
              <label>Voucher Length</label>
              <k-input :placeholder="'20'" v-model="this.appModel.exsited.voucherCodeLength" />
              <div class="error-msg">{{errorMesseges?.voucherCodeLength}}</div>
            </div>
          </div>
        </div>
        <div v-if="this.selectedPlatform?.id == 2">
          <div class="col-sm-12">
            <p class="font-14 font-w-700 mb-12">
              Please enter your platform's client ID and secret below:
            </p>
          </div>
          <div class="col-sm-12">
            <div class="k-form-field text-start">
              <label>Email Processor Key</label>
              <k-input :placeholder="'Email Processor Key'" v-model="this.appModel.emailProcessor.emailProcessorKey" />
              <div class="error-msg">{{errorMesseges?.emailProcessorKey}}</div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="k-form-field text-start">
              <label>Email Processor Handler</label>
              <k-input :placeholder="'Email Processor Handler'"
                v-model="this.appModel.emailProcessor.emailProcessorHandler" />
              <div class="error-msg">
                {{errorMesseges?.emailProcessorHandler}}
              </div>
            </div>
          </div>
        </div>
        <div v-if="this.selectedPlatform?.id == 3">
          <div class="col-sm-12">
            <p class="font-14 font-w-700 mb-12">
              Please enter your platform's client ID and secret below:
            </p>
          </div>
          <div class="col-sm-12">
            <div class="k-form-field text-start">
              <label>Client ID</label>
              <k-input  :placeholder="'127nghh565dhfwdf4d5565'"
                v-model="this.appModel.webcommander.clientId" />
              <div class="error-msg">{{errorMesseges?.webcommanderClientId}}</div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="k-form-field text-start">
              <label>Client Secret</label>
              <k-input type="password" :placeholder="'****'" v-model="this.appModel.webcommander.clientSecret" />
              <div class="error-msg">{{errorMesseges?.webcommanderClientSecret}}</div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="k-form-field text-start">
              <label>Redirect Uri</label>
              <k-input :placeholder="'Redirect Uri'" v-model="this.appModel.webcommander.redirectUri" />
              <div class="error-msg">{{errorMesseges?.webcommanderRedirectUri}}</div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="k-form-field text-start">
              <label>Source Api</label>
              <k-input :placeholder="'Source API'" v-model="this.appModel.webcommander.apiUri" />
              <div class="error-msg">{{errorMesseges?.webcommanderApiUri}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-slot:footer>
    <div class="d-flex justify-content-end">
      <!-- <div class="me-1">
        <kbutton @click="this.modalHandler" :class="''">Cancel</kbutton>
      </div> -->
      <div class="ms-1">
        <kbutton @click="connectAppHandler" :theme-color="'primary'" :class="''">
          Connect</kbutton>
      </div>
    </div>
  </template>
</modal>

<AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>

<AppLoader v-if="this.isLoading"></AppLoader>