<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.16875 7.45625L4.61875 7.84375C4.54062 8.13438 4.5 8.4375 4.5 8.75C4.5 10.6812 6.06875 12.25 8 12.25C9.93125 12.25 11.5 10.6812 11.5 8.75C11.5 6.81875 9.93125 5.25 8 5.25V7L4 4.5L8 2V3.75C10.7625 3.75 13 5.9875 13 8.75C13 11.5125 10.7625 13.75 8 13.75C5.2375 13.75 3 11.5125 3 8.75C3 8.30312 3.05938 7.86875 3.16875 7.45625Z" fill="#24243A"/>
    </svg>
</template>
<script>
    export default {
        name: "inputReloadIcon"
    }
</script>
<style scoped></style>