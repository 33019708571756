<!-- <AppSnackBar :message="showMsg" :notificationType="'success'" v-if="isClubUpdated"></AppSnackBar> -->
<AppLoader v-if="imgUploading"></AppLoader>
<AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>
<AppLoader v-if="loader"></AppLoader>

<div class="">
  <!-- <h3 class="font-14 font-w-700 text-color">Club Setting</h3>
  <p class="m-0 font-12 text-color">Set up club details and website links</p> -->
  <!-- <div class="p-30 p-0-vsm max-height-100-310 overflow-y-auto max-height-100-sm"> -->
  <div class="p-30 p-0-vsm mb-5">
    <div class="row width-890 m-0-vsm">
      <div class="blank-space-lg2"></div>
      <div class="col-md-5 col-12 mb-3 mb-md-0">
        <h3 class="font-14 font-w-700 text-color">{{isClub()}} Details</h3>
        <p class="m-0 font-12 text-color">
          Provide all basic information for {{isClub()}}
        </p>
      </div>

      <div class="col-md-7 col-12">
        <k-form>
          <div class="k-form-field">
            <div class="k-form-field">
              <label>{{isClub()}} Name *</label>
              <k-input type="text" :placeholder="isClub() +` Name`" v-model="club.name"
                @keyup="errorMessages.Name[0]=''">
              </k-input>
              <div class="error-msg">{{this.errorMessages?.Name?.[0]}}</div>
            </div>

            <div class="k-form-field">
              <label>{{isClub()}} Address</label>
              <k-input type="text" :placeholder="isClub() +` Address`" v-model="club.address"
                @keyup="errorMessages.Address[0]=''">
              </k-input>
              <div class="error-msg">{{this.errorMessages?.Address?.[0]}}</div>
            </div>

            <div class="k-form-field">
              <label>{{isClub()}} Abbreviation</label>
              <k-input type="text" :placeholder="isClub() +` Abbreviation`" v-model="club.description"
                @keyup="errorMessages.Description[0]=''"></k-input>
              <div class="error-msg">
                {{this.errorMessages?.Description?.[0]}}
              </div>
            </div>

            <div>
              <label>{{isClub()}} Logo</label>
              <div class="file-uploader-custom mb-4 w-100 height-110 close-right-top">
                <div class="uplodade-img-box size-72">
                  <img v-if="tempPhotoPath || club.logo || club.logoId"
                    :src="tempPhotoPath ? tempPhotoPath : club.logo?.url" />
                  <img v-else src="../../../../assets/images/login-bg.png" />
                </div>
                <kbutton v-if="tempPhotoPath || club.logo || club.logoId" :icon="'close'" :size="'small'"
                  @click="removeDocument"></kbutton>
                <input type="file" @change="uploadProfilePhoto" accept="image/png, image/jpeg" />
              </div>
            </div>

            <div class="k-form-field">
              <label for="">Time Zone</label>
              <dropdownlist :data-items="this.timeZonesList" :text-field="'name'" :data-item-key="'id'"
                :filterable="true" @filterchange="filterChange" :value="this.timeZoneValue"
                @change="this.handleTimeZoneChangeValue">
              </dropdownlist>
            </div>
          </div>
        </k-form>
      </div>
    </div>
  </div>

  <div class="bottom-fixed-button-wrap d-flex justify-content-between bg-white">
    <kbutton @click="updateClubCancel" :class="'flex-none'">Cancel</kbutton>
    <kbutton v-if="!imgUploading" :theme-color="'primary'" :disabled="this.hasPutPermission() == false" @click="updateClub" :class="'flex-none'">Save Changes
    </kbutton>
  </div>
</div>