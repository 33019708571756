<modal title="Change User Role" :handler="modalVisible" :cssClass="'modal-md-2'" :footer="false"
    @modalHandler="cancelModal">
    <template v-slot:content>
        <div class="p-4">
            <p class="font-16 m-0 py-5 pt-4 text-center">
                You are attempting to change the user's role. User can access your club based on the selected role. Are you sure you want to change role for this user?
            </p>
        </div>
        <dialog-actions-bar>
            <kbutton @click="cancelModal">Cancel</kbutton>
            <kbutton :theme-color="'primary'" @click="changeRoleHandler">Change Role</kbutton>
        </dialog-actions-bar>
    </template>
</modal>