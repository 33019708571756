<template src="./AccessControl.html"></template>
<style lang="scss">
@import "./AccessControl.scss";
</style>

<script>
import { Switch } from "@progress/kendo-vue-inputs";
import { Form } from "@progress/kendo-vue-form";
import { Button } from "@progress/kendo-vue-buttons";
import AppSnackBar from "../../../common/AppSnackBar/AppSnackBar";
import AppLoader from "../../../common/AppLoader/AppLoader";
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import AddUserModal from "./addUserModal/AddUserModal.vue";
import AppPopUp from "@/components/common/AppPopUp/AppPopUp.vue";
import RoleMemberDeleteModal from "./roleMemberDeleteModal/RoleMemberDeleteModal.vue";
import ChangeRoleWarningModal from "./changeRoleWarningModal/ChangeRoleWarningModal.vue";

export default {
  components: {
    "k-switch": Switch,
    "k-form": Form,
    kbutton: Button,
    AppSnackBar,
    AppLoader,
    Grid: Grid,
    toolbar: GridToolbar,
    GridNoRecords,
    "k-input": Input,
    dropdownlist: DropDownList,
    DropDownButton,
    AddUserModal,
    AppPopUp,
    RoleMemberDeleteModal,
    ChangeRoleWarningModal,
  },
  data() {
    const clubId = this.$route.params.id;
    return {
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      clubId,
      notifyMessage: "Success",
      notifyType: "success",
      isNotify: false,
      loader: false,
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100, 200, 500, 1000],
        previousNext: true,
        responsive: false,
      },
      searchWord: "",
      changeRoleForAdmin: [
        {
          text: "Change Role To Report Viewer",
          dropId: 2,
        },
        {
          text: "Delete",
          dropId: 3,
        },
      ],
      changeRoleForViewer: [
        {
          text: "Change Role To Club Admin",
          dropId: 1,
        },
        {
          text: "Delete",
          dropId: 3,
        },
      ],
      payloadDelete: "",
      payloadChangeRole: "",
      debounce: null,
      searchText: "",
      isSearching: false,
    };
  },
  computed: {
    userDetails() {
      return this.$store.getters.userDetails;
    },
    usersData() {
      return this.$store.state.systemDashboard.usersData;
    },
    total() {
      return this.usersData ? this.usersData.size : 0;
    },
    take() {
      return this.usersData ? this.usersData.limit : 0;
    },
    skip() {
      return this.usersData ? this.usersData.offset : 0;
    },
    isShowMsg() {
      return this.$store.state.voucherManageRecipients.showMsg;
    },
    showMsg() {
      return this.$store.state.voucherManageRecipients.status;
    },
    addUserModalVisibility() {
      return this.$store.state.systemDashboard.addUserModalVisibility;
    },
    userDataLoading() {
      ;
      return this.$store.state.systemDashboard.userDataLoading;
    },
    deleteUserLoading() {
      return this.$store.state.systemDashboard.deleteUserLoading;
    },
    changeRoleLoading() {
      return this.$store.state.systemDashboard.updateRoleLoading;
    },
    deleteRoleMemberPopUpVisibility() {
      return this.$store.state.systemDashboard.deleteRoleMemberPopUpVisibility;
    },
    changeRoleWarningPopUpVisibility() {
      return this.$store.state.systemDashboard.changeRolePopUpVisibility;
    },
    columns() {
      return [
        {
          field: "email",
          title: "Email",
        },
        {
          field: "name",
          title: "Name",
        },
        {
          field: "role.name",
          title: "Role",
        },
        {
          field: "",
          title: "",
          cell: "actions",
        },
      ];
    },
  },
  mounted() {
    // this.getUsersList(this.clubId);
  },
  methods: {
    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.onSearch(event);
      }, 600);
    },

    onSearch(e) {
      this.searchText = e.value;
      this.isSearching = true;
      ;
      if (this.searchText.length === 0) {
        setTimeout(() => {
          this.isSearching = false;
        }, 2000);
      }
      let payload = {
        clubId: this.clubId,
        searchText: this.searchText,
      };
      this.$store.dispatch("getUsersList", payload);
    },
    getDropIdForChangeRole(e, item) {
      ;
      if ([1, 2].includes(e)) {
        this.payloadChangeRole = {
          clubId: this.clubId,
          clubSystemUserId: item.clubSystemUserId,
          roleId: e,
          searchText: this.searchText,
          limit: this.take,
          offset: this.skip,
        };
        setTimeout(() => {
          this.$store.commit("CHANGE_ROLE_POP_UP_VISIBILITY", true);
        }, 250);
      }
      if (e === 3) {
        this.payloadDelete = {
          clubId: this.clubId,
          ...item,
          searchText: this.searchText,
          limit: this.take,
          offset: this.skip,
        };
        setTimeout(() => {
          this.$store.commit("DELETE_ROLE_MEMBER_POP_UP_VISIBILITY", true);
        }, 250);
      }
    },
    addUserHandler() {
      this.$store.commit("ADD_USER_MODAL_VISIBILITY", true);
    },
    dataStateChange(event) {
      this.take = event.data.take;
      this.skip = event.data.skip;

      let payload = {
        clubId: +this.clubId,
        searchText: this.searchText,
        limit: event.data.take,
        offset: event.data.skip,
      };
      this.$store.dispatch("getUsersList", payload);
    },
    updateClubCancel() {
      this.$router.push(`/club-details/${this.clubId}/dashboard`);
    },
    getUsersList(clubId) {
      this.$store.dispatch("getUsersList", clubId);
    },
    save() { },
    isClub() {
      return this.club.isAssociation ? "Association" : "Club";
    },
    showNotification(message, type) {
      ;
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
  },
};
</script>
