<template src="./CreateOrUpdateApp.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import modal from "@/components/common/AppModal/AppModal.vue";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { Input } from "@progress/kendo-vue-inputs";
import {
  createAutobillOptionService,
  updateAutobillOptionService,
  createEmailProcessorService,
  updateEmailProcessorService,
  createWebcommanderService,
  updateWebcommanderService,
} from "@/services/clubDetails/integrations.service";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
export default {
  props: {
    fromUpdate: {
      type: Boolean,
    },
    appData: {
      type: Object,
    },
  },
  components: {
    kbutton: Button,
    modal,
    numericTextBox: NumericTextBox,
    datePicker: DatePicker,
    "k-input": Input,
    dropdownlist: DropDownList,
    AppSnackBar,
    AppLoader,
  },
  data() {
    return {
      isLoading: false,
      clubId: this.$route.params.id,
      platformItems: [
        { id: 1, text: "Exsited" },
        { id: 2, text: "Sendgrid" },
        { id: 3, text: "Webcommander" },
      ],
      selectedPlatform: { id: 1, text: "Exsited" },
      errorMesseges: {},
      appModel: {
        exsited: {
          clientId: "",
          clientSecret: "",
          redirectUri: "",
          voucherCodeLength: 0,
        },
        emailProcessor: {
          emailProcessorKey: "",
          emailProcessorHandler: "",
        },
        webcommander: {
          clientId: "",
          clientSecret: "",
          redirectUri: "",
          apiUri: "",
        },
      },
    };
  },
  mounted() {
    if (this.fromUpdate) {
      if (this.appData?.name == "Exsited") {
        this.selectedPlatform = { id: 1, text: "Exsited" };
        this.appModel.exsited = this.appData;
      } else if (this.appData?.name == "SendGrid") {
        this.selectedPlatform = { id: 2, text: "Sendgrid" };
        this.appModel.emailProcessor = this.appData;
      } else {
        this.selectedPlatform = { id: 3, text: "Webcommander" };
        this.appModel.webcommander = this.appData;
      }
    }
  },
  computed: {},
  methods: {
    platformChangeHandler(e) {
      this.errorMesseges = {};
    },
    connectAppHandler() {
      if (this.validateForm()) {
        if (this.selectedPlatform?.id == 1) {
          this.processAutobillOptionsHandler();
        }
        if (this.selectedPlatform?.id == 2) {
          this.processEmailProcessorHandler();
        }
        if (this.selectedPlatform?.id == 3) {
          this.processWebcommanderHandler();
        }
      }
    },
    processAutobillOptionsHandler() {
      const payload = {
        ...this.appModel.exsited,
        clubId: this.clubId,
      };

      this.isLoading = true;

      if (this.fromUpdate) {
        updateAutobillOptionService(payload)
          .then((response) => {
            this.isLoading = false;
            this.$emit("close", false, true);
            this.$store.commit("SHOW_NOTIFICATION", {
              message: "App updated successfully.",
              type: "success",
            });
          })
          .catch((error) => {
            this.isLoading = false;
            this.$store.commit("SHOW_NOTIFICATION", {
              message: "Something went wrong, please try again.",
              type: "error",
            });
          });
      } else {
        createAutobillOptionService(payload)
          .then((response) => {
            this.isLoading = false;
            this.$emit("close", false, true);
            this.$store.commit("SHOW_NOTIFICATION", {
              message: "App created successfully.",
              type: "success",
            });
          })
          .catch((error) => {
            this.isLoading = false;
            const message = error.response.data?.Status?.Message;
            this.$store.commit("SHOW_NOTIFICATION", {
              message: message || "Something went wrong, please try again.",
              type: "error",
            });
          });
      }
    },
    processWebcommanderHandler() {
      const payload = {
        ...this.appModel.webcommander,
        clubId: this.clubId,
      };

      this.isLoading = true;

      if (this.fromUpdate) {
        updateWebcommanderService(payload)
          .then((response) => {
            this.isLoading = false;
            this.$emit("close", false, true);
            this.$store.commit("SHOW_NOTIFICATION", {
              message: "App updated successfully.",
              type: "success",
            });
          })
          .catch((error) => {
            this.isLoading = false;
            this.$store.commit("SHOW_NOTIFICATION", {
              message: "Something went wrong, please try again.",
              type: "error",
            });
          });
      } else {
        payload.clientCredentialSource = 1;
        payload.id = 0;

        createWebcommanderService(payload)
          .then((response) => {
            this.isLoading = false;
            this.$emit("close", false, true);
            this.$store.commit("SHOW_NOTIFICATION", {
              message: "App created successfully.",
              type: "success",
            });
          })
          .catch((error) => {
            this.isLoading = false;
            const message = error.response.data?.Status?.Message;
            this.$store.commit("SHOW_NOTIFICATION", {
              message: message || "Something went wrong, please try again.",
              type: "error",
            });
          });
      }
    },
    processEmailProcessorHandler() {
      const payload = {
        ...this.appModel.emailProcessor,
        clubId: this.clubId,
      };

      this.isLoading = true;

      if (this.fromUpdate) {
        updateEmailProcessorService(payload)
          .then((response) => {
            this.isLoading = false;
            this.$emit("close", false, true);
            this.$store.commit("SHOW_NOTIFICATION", {
              message: "App updated successfully.",
              type: "success",
            });
          })
          .catch((error) => {
            this.isLoading = false;
            this.$store.commit("SHOW_NOTIFICATION", {
              message: "Something went wrong, please try again.",
              type: "error",
            });
          });
      } else {
        createEmailProcessorService(payload)
          .then((response) => {
            this.isLoading = false;
            this.$emit("close", false, true);
            this.$store.commit("SHOW_NOTIFICATION", {
              message: "App created successfully.",
              type: "success",
            });
          })
          .catch((error) => {
            this.isLoading = false;
            const message = error.response.data?.Status?.Message;
            this.$store.commit("SHOW_NOTIFICATION", {
              message: message || "Something went wrong, please try again.",
              type: "error",
            });
          });
      }
    },
    modalHandler() {
      this.$emit("close", false);
    },
    validateForm() {
      let isValid = true;
      this.errorMesseges = {};
      if (this.selectedPlatform.id === 1) {
        if (!this.appModel.exsited.clientId) {
          this.errorMesseges.clientId = "Client Id is required";
          isValid = false;
        }
        if (!this.appModel.exsited.clientSecret) {
          this.errorMesseges.clientSecret = "Client Secret is required";
          isValid = false;
        }
        if (!this.appModel.exsited.redirectUri) {
          this.errorMesseges.redirectUri = "Redirect Uri is required";
          isValid = false;
        }
        if (!this.appModel.exsited.voucherCodeLength) {
          this.errorMesseges.voucherCodeLength =
            "Voucher Code Length is required";
          isValid = false;
        }
      } else if (this.selectedPlatform.id === 2) {
        if (!this.appModel.emailProcessor.emailProcessorKey) {
          this.errorMesseges.emailProcessorKey =
            "Email Processor Key is required";
          isValid = false;
        }
        if (!this.appModel.emailProcessor.emailProcessorHandler) {
          this.errorMesseges.emailProcessorHandler =
            "Email Processor Handler is required";
          isValid = false;
        }
      } else {
        if (!this.appModel.webcommander.clientId) {
          this.errorMesseges.webcommanderClientId = "Client Id is required";
          isValid = false;
        }
        if (!this.appModel.webcommander.clientSecret) {
          this.errorMesseges.webcommanderClientSecret =
            "Client Secret is required";
          isValid = false;
        }
        if (!this.appModel.webcommander.redirectUri) {
          this.errorMesseges.webcommanderRedirectUri =
            "Redirect Uri is required";
          isValid = false;
        }
        if (!this.appModel.webcommander.apiUri) {
          this.errorMesseges.webcommanderApiUri = "Source Api is required";
          isValid = false;
        }
      }
      return isValid;
    },
  },
};
</script>

<style></style>
