<modal :class="'max-height-100-145'" title="Add User" :isLoading="addUserLoading" :handler="true"
  @modalHandler="this.modalHandler" :cssClass="'modal-md-2'">
  <template v-slot:content>
    <div class="pt-4 px-2 pb-4">
      <div class="row">
        <div class="col-sm-12">
          <div class="k-form-field text-start">
            <label>Email Address*</label>
            <k-input @keyup="clearEmailError" v-model="this.email" :placeholder="'user@domain.com'" />
            <div class="error-msg">{{this.addUserErrors?.Email?.[0] || emailError}}</div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="k-form-field text-start">
            <label>First Name*</label>
            <k-input @keyup="firstNameError=''" v-model="this.firstName" :placeholder="'Input First Name'" />
            <div class="error-msg">{{ firstNameError }}</div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="k-form-field text-start">
            <label>Last Name*</label>
            <k-input @keyup="lastNameError=''" v-model="this.lastName" :placeholder="'Input Last Name'" />
            <div class="error-msg">{{ lastNameError }}</div>

          </div>
        </div>
        <div class="col-md-12 col-sm-12 mb-24">
          <div class="k-form-field text-start">
            <label>Select Role</label>
            <dropdownlist :data-items="this.roleItems" :text-field="'text'" :data-item-key="'id'"
              :value="this.roleValue" @change="this.handleRoleChangeValue">
            </dropdownlist>
          </div>
        </div>
        <div class="col-md-7 col-sm-12">
          <div class="k-form-field text-start">
            <label>Password*</label>
            <k-input @keyup="passwordError=''" v-model="this.passwordValue" :placeholder="'Enter Password'" />
            <div class="error-msg">{{ passwordError }}</div>
          </div>
        </div>
        <div class="col-md-5 col-sm-12">
          <div class="k-form-field text-start w-100 mt-32">
            <kbutton @click="this.generatePasswordHandler" :theme-color="'primary'" :class="'w-100'" class="w-100">Auto
              Generate</kbutton>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-slot:footer>
    <div class="d-flex justify-content-end">
      <div class="me-1">
        <kbutton @click="this.modalHandler" :class="''">Cancel</kbutton>
      </div>
      <div class="ms-1">
        <kbutton :disabled="!email || !passwordValue || !firstName || !lastName" @click="this.addUserHandler"
          :theme-color="'primary'" :class="''">
          Done</kbutton>
      </div>
    </div>
  </template>
</modal>

<AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>