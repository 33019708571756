<AppSnackBar
  :message="showMsg"
  :notificationType="'success'"
  v-if="isClubUpdated"
></AppSnackBar>
<app-loader v-if="clubUpdateLoading"></app-loader>
<div class="dashboard-content">
  <div class="max-height-100--278 min-h-100vh--278 overflow-y-auto bg-white">
    <div class="p-24 p-0-sm">
      <div v-if="isPlatformAdmin">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center border-top-normal-1 border-right-normal-1 border-left-normal-1 p-2"
        >
          <div class="left d-flex w-100-vsm mb-10-vsm">
            <div
              :class=" activeTab == tab.name  ? 'btn-active' : ''"
              :ref="tab.name"
              v-for="tab in tabNames"
            >
              <kbutton
                :ref="tab.name"
                @click="switchTabHandlers(tab.name)"
                :theme-color="'primary'"
                :fill-mode="'flat'"
                :class="'radius-0 color-black'"
                >{{tab.value}}
              </kbutton>
            </div>
          </div>
          <!-- <div class="right d-flex flex-wrap w-100-vsm">
              <div class="search-box-wrap width-220 w-100-vsm me-3 mr-0-vsm mb-2 mb-sm-0">
                <span class="k-icon k-i-zoom"></span>
                <span class="k-textbox k-grid-search k-display-flex">
                    <k-input :class="'mb-0 width-220 w-100-vsm'" placeholder="Search"
                        @input="debounceSearch">
                    </k-input>
                </span>
              </div>

              <div class="w-100-vsm">
                  <kbutton :size="'medium'" :theme-color="'primary'"
                      :class="'w-100-vsm'">Send Invitation
                  </kbutton>
              </div>
            </div> -->
        </div>
        <component :is="activeTab"></component>
      </div>
      <div v-if="!isPlatformAdmin">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center border-top-normal-1 border-right-normal-1 border-left-normal-1 p-2"
        >
          <div class="left d-flex">
            <div
              :class=" activeTab == tab.name  ? 'btn-active' : ''"
              :ref="tab.name"
              v-for="tab in tabNamesForUser"
            >
              <kbutton
                :ref="tab.name"
                @click="switchTabHandlers(tab.name)"
                :theme-color="'primary'"
                :fill-mode="'flat'"
                :class="'radius-0 color-black'"
                >{{tab.value}}
              </kbutton>
            </div>
          </div>
          <!-- <div class="right d-flex">
              <div class="">
                <kbutton :size="'medium'" :theme-color="'primary'"
                    :class="'w-100-vsm'">Create Role
                </kbutton>
              </div>
            </div> -->
        </div>
        <component :is="activeTab"></component>
      </div>
    </div>
  </div>
</div>
