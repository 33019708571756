<div class="">
  <div class="p-30 p-0-vsm mb-5">
    <div class="row width-890 m-0-vsm mb-3">
      <div class="blank-space-lg2"></div>
      <div class="col-md-5">
        <h3 class="font-14 font-w-700 text-color">Client ID & Client Secret</h3>
        <p class="m-0 font-12 text-color">
          Provide all basic information for Club
        </p>
      </div>
      <div class="col-md-7">
        <k-form>
          <div class="k-form-field">
            <div class="k-form-field client-id-secret-input-box">
              <label>Client ID</label>
              <k-input
                type="text"
                :value="club.clientId"
                :disabled="true"
                :class="'pr-36'"
              >
              </k-input>
              <div class="cilent-id-secret-buttons d-flex gap-2">
                <!-- <kbutton :fill-mode="'flat'" :class="'p-0'">
                  <component :is="'inputReloadIcon'"></component>
                </kbutton> -->
                <kbutton
                  @click="compyToClipboard(club.clientId)"
                  :fill-mode="'flat'"
                  :class="'p-0'"
                >
                  <component :is="'inputCopyIcon'"></component>
                </kbutton>
              </div>
            </div>
            <div class="k-form-field client-id-secret-input-box">
              <label>Client Secret</label>
              <k-input
                :class="'pr-36'"
                type="password"
                :value="club.clientSecret"
                :disabled="true"
              >
              </k-input>
              <div class="cilent-id-secret-buttons d-flex gap-2">
                <!-- <kbutton :fill-mode="'flat'" :class="'p-0'">
                  <component :is="'inputReloadIcon'"></component>
                </kbutton>
                <kbutton :fill-mode="'flat'" :class="'p-0'">
                  <component :is="'inputEyeIcon'"></component>
                </kbutton> -->
                <kbutton
                  @click="compyToClipboard(club.clientSecret)"
                  :fill-mode="'flat'"
                  :class="'p-0'"
                >
                  <component :is="'inputCopyIcon'"></component>
                </kbutton>
              </div>
            </div>
          </div>
          <kbutton
            v-if="!club.clientId && !club.clientSecret"
            @click="generateCredentials"
          >
            Generate
          </kbutton>

          <kbutton
            v-if="club.clientId && club.clientSecret"
            @click="regenerateCredentials"
          >
            Regenerate
          </kbutton>
        </k-form>
      </div>
    </div>
    <div class="mt-3 mb-5 border-top-normal-3"></div>
    <div class="row width-890 m-0-vsm">
      <div class="blank-space-lg2"></div>
      <div class="col-md-5">
        <h3 class="font-14 font-w-700 text-color">Connected Apps</h3>
        <p class="m-0 font-12 text-color">
          Provide links for club website <br />
          and social medias
        </p>
      </div>
      <div class="col-md-7">
        <div
          class="connected-apps-box d-flex justify-content-between align-content-center bg-white p-16 mb-12 border-normal-3 border-radius-3"
          v-for="(item) in Object.values(appModel)"
          :key="item?.id"
        >
          <div class="left d-flex">
            <div class="icon">
              <component :is="'connectedAppsSendGrid'"></component>
              <!-- <component :is="'connectedAppsExsited'"></component>                           -->
            </div>
            <div class="content d-flex flex-column ml-16">
              <label class="font-14 font-w-700 mb-0 text-color"
                >{{item?.name}}</label
              >
              <kbutton
                :class="'p-0 font-14 text-decoration-underline text-color bg-transparent border-0 w-54'"
                @click="UpdateAppHandler(item)"
                :disabled="this.hasPutPermission() == false"
              >
                Update
              </kbutton>
            </div>
          </div>
          <div class="right d-flex align-content-center">
            <kbutton
              @click="deleteAppHandler(item)"
              :fill-mode="'flat'"
              :class="'p-0'"
              :disabled="this.hasDeletePermission() == false"
            >
              <component :is="'deleteSmall_1'"></component>
            </kbutton>
          </div>
        </div>
        <kbutton
          :fill-mode="'flat'"
          @click="AddNewAppHandler"
          :class="''"
          :disabled="this.hasPostPermission() == false"
        >
          + Add New App</kbutton
        >
      </div>
    </div>
  </div>
</div>

<CreateOrUpdateApp
  v-if="showCreateOrUpdateApp"
  :fromUpdate="fromUpdate"
  :appData="appData"
  @close="toggleCreateOrUpdateApp"
/>

<WarningModal
  v-if="deleteModalVisibility"
  :modalDetails="deleteModalDetails"
  :onCancel="onCancelDelete"
  :onConfirm="onConfirmDelete"
/>

<AppLoader
  v-if="isLoading || isAutobillOptionLoading || isEmailProcessorLoading || isWebcommanderLoading"
></AppLoader>
