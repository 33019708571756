<template src="./integrations.html"></template>
<style lang="scss">
@import "./integrations.scss";
</style>

<script>
import { Input } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import CreateOrUpdateApp from "./CreateOrUpdateApp/CreateOrUpdateApp.vue";

import inputReloadIcon from "../../../svg-icons/inputReloadIcon.vue";
import inputCopyIcon from "../../../svg-icons/inputCopyIcon.vue";
import inputEyeIcon from "../../../svg-icons/inputEyeIcon.vue";
import deleteSmall_1 from "../../../svg-icons/deleteSmall_1.vue";
import connectedAppsSendGrid from "../../../svg-icons/connectedAppsSendGrid.vue";
import connectedAppsExsited from "../../../svg-icons/connectedAppsExsited.vue";
import WarningModal from "../../emailCampaign/warningModal/WarningModal.vue";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";

import {
  getAutobillOptionService,
  getEmailProcessorService,
  deleteAutobillOptionService,
  deleteEmailProcessorService,
  getWebcommanderService,
  deleteWebcommanderService,
  createClientCredentials,
  updateClientCredentials,
} from "@/services/clubDetails/integrations.service";

export default {
  inject: [
    "hasPutPermission",
    "hasDeletePermission",
    "hasPostPermission",
    "hasGetPermission",
  ],
  components: {
    "k-input": Input,
    kbutton: Button,
    CreateOrUpdateApp,
    inputReloadIcon,
    inputCopyIcon,
    inputEyeIcon,
    deleteSmall_1,
    connectedAppsSendGrid,
    connectedAppsExsited,
    WarningModal,
    AppLoader,
  },
  data() {
    return {
      isLoading: false,
      deleteModalVisibility: false,
      deleteModalDetails: {
        title: "Delete App",
        message:
          "Are you sure you want to delete this app? This action cannot be undone.",
        confirmButton: "Delete",
        cancelButton: "Cancel",
      },
      selectedAppForDelete: {},
      showCreateOrUpdateApp: false,
      isAutobillOptionLoading: false,
      isEmailProcessorLoading: false,
      isWebcommanderLoading: false,
      fromUpdate: false,
      appModel: {},
      appData: {},
      client: "ervferverv",
      clubId: this.$route.params.id,
      connectedAppsList: [
        {
          name: "Sendgrid",
          status: "Connected",
          id: 1,
        },
        {
          name: "Exsited",
          status: "Connected",
          id: 2,
        },
      ],
    };
  },
  computed: {
    club() {
      return this.$store.getters.clubDetails;
    },
    clubDetailsLoading() {
      return this.$store.getters.clubDetailsLoading;
    },
  },
  mounted() {
    this.getConnectedApps();
  },

  setup() {},
  methods: {
    generateCredentials() {
      this.isLoading = true;
      createClientCredentials({
        clubId: this.clubId,
      })
        .then((response) => {
          this.isLoading = false;
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Client credentials generated successfully.",
            type: "success",
          });
          this.$store.dispatch("getClub", {
            clubId: this.clubId,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Something went wrong. Please try again.",
            type: "error",
          });
        });
    },
    regenerateCredentials() {
      this.isLoading = true;
      updateClientCredentials({
        clubId: this.clubId,
      })
        .then((response) => {
          this.isLoading = false;
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Client credentials regenerated successfully.",
            type: "success",
          });
          this.$store.dispatch("getClub", {
            clubId: this.clubId,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Something went wrong. Please try again.",
            type: "error",
          });
        });
    },
    compyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "Copied to clipboard",
        type: "success",
      });
    },
    onCancelDelete() {
      this.deleteModalVisibility = false;
    },
    onConfirmDelete() {
      const payload = {
        clubId: this.clubId,
        id: this.selectedAppForDelete.id,
      };
      this.isLoading = true;
      if (this.selectedAppForDelete.name === "SendGrid") {
        deleteEmailProcessorService(payload)
          .then((response) => {
            this.isLoading = false;
            this.deleteModalVisibility = false;
            this.getConnectedApps();
            this.$store.commit("SHOW_NOTIFICATION", {
              message: "App deleted successfully.",
              type: "success",
            });
          })
          .catch((error) => {
            this.isLoading = false;
            this.deleteModalVisibility = false;
            this.$store.commit("SHOW_NOTIFICATION", {
              message: "Something went wrong. Please try again.",
              type: "error",
            });
          });
      } else if (this.selectedAppForDelete.name === "Exsited") {
        deleteAutobillOptionService(payload)
          .then((response) => {
            this.isLoading = false;
            this.deleteModalVisibility = false;
            this.getConnectedApps();
            this.$store.commit("SHOW_NOTIFICATION", {
              message: "App deleted successfully.",
              type: "success",
            });
          })
          .catch((error) => {
            this.isLoading = false;
            this.deleteModalVisibility = false;
            this.$store.commit("SHOW_NOTIFICATION", {
              message: "Something went wrong. Please try again.",
              type: "error",
            });
          });
      } else if (this.selectedAppForDelete.name === "Webcommander") {
        deleteWebcommanderService(payload)
          .then((response) => {
            this.isLoading = false;
            this.deleteModalVisibility = false;
            this.getConnectedApps();
            this.$store.commit("SHOW_NOTIFICATION", {
              message: "App deleted successfully.",
              type: "success",
            });
          })
          .catch((error) => {
            this.isLoading = false;
            this.deleteModalVisibility = false;
            this.$store.commit("SHOW_NOTIFICATION", {
              message: "Something went wrong. Please try again.",
              type: "error",
            });
          });
      }
    },
    deleteAppHandler(data) {
      this.deleteModalVisibility = true;
      this.selectedAppForDelete = data;
    },
    UpdateAppHandler(data) {
      this.appData = data;
      this.fromUpdate = true;
      this.toggleCreateOrUpdateApp(true);
    },
    getConnectedApps() {
      this.isAutobillOptionLoading = true;
      this.isEmailProcessorLoading = true;
      this.isWebcommanderLoading = true;

      getAutobillOptionService(this.clubId)
        .then((response) => {
          this.isAutobillOptionLoading = false;
          if (response.data?.data?.id) {
            let data = {
              ...response.data?.data,
              name: "Exsited",
              status: "Connected",
            };
            this.appModel.exsited = data;
          } else {
            delete this.appModel.exsited;
          }
        })
        .catch((error) => {
          this.isAutobillOptionLoading = false;
        });

      getEmailProcessorService(this.clubId)
        .then((response) => {
          this.isEmailProcessorLoading = false;
          if (response.data?.data?.id) {
            let data = {
              ...response.data?.data,
              name: "SendGrid",
              status: "Connected",
            };
            this.appModel.emailProcessor = data;
          } else {
            delete this.appModel.emailProcessor;
          }
        })
        .catch((error) => {
          this.isEmailProcessorLoading = false;
        });

      getWebcommanderService(this.clubId)
        .then((response) => {
          this.isWebcommanderLoading = false;
          if (response.data?.data?.id) {
            let data = {
              ...response.data?.data,
              name: "Webcommander",
              status: "Connected",
            };
            this.appModel.webcommander = data;
          } else {
            delete this.appModel.webcommander;
          }
        })
        .catch((error) => {
          this.isWebcommanderLoading = false;
        });
    },
    AddNewAppHandler() {
      this.toggleCreateOrUpdateApp(true);
    },
    toggleCreateOrUpdateApp(value, reload = false) {
      this.showCreateOrUpdateApp = value;
      if (!value) {
        if (reload) {
          this.getConnectedApps();
        }
        this.fromUpdate = false;
      }
    },
  },
};
</script>
