import { DELETE, GET, POST, PUT } from "@/service";

export const createAutobillOptionService = (payload: any) => {
  return POST(`api/v1/club/${payload?.clubId}/create-autobill-option`, payload);
};

export const updateAutobillOptionService = (payload: any) => {
  return PUT(`api/v1/club/${payload?.clubId}/update-autobill-option`, payload);
};

export const deleteAutobillOptionService = (payload: any) => {
  return DELETE(`api/v1/club/${payload?.clubId}/delete-autobill-option`, {
    data: payload,
  });
};

export const createEmailProcessorService = (payload: any) => {
  return POST(`api/v1/club/${payload?.clubId}/create-email-processor`, payload);
};

export const updateEmailProcessorService = (payload: any) => {
  return PUT(`api/v1/club/${payload?.clubId}/update-email-processor`, payload);
};

export const deleteEmailProcessorService = (payload: any) => {
  return DELETE(`api/v1/club/${payload?.clubId}/delete-email-processor`, {
    data: payload,
  });
};

export const getAutobillOptionService = (clubId: number) => {
  return GET(`api/v1/Club/get-autobill-option?ClubId=${clubId}`);
};

export const getEmailProcessorService = (clubId: number) => {
  return GET(`api/v1/Club/get-email-processor?ClubId=${clubId}`);
};

export const getWebcommanderService = (clubId: number) => {
  return GET(`api/v1/Club/get-external-client-credential?ClubId=${clubId}`);
};

export const createWebcommanderService = (payload: any) => {
  return POST(
    `api/v1/club/${payload?.clubId}/create-external-client-credential`,
    payload
  );
};

export const updateWebcommanderService = (payload: any) => {
  return PUT(
    `api/v1/club/${payload?.clubId}/update-external-client-credential`,
    payload
  );
};

export const deleteWebcommanderService = (payload: any) => {
  return DELETE(
    `api/v1/club/${payload?.clubId}/delete-external-client-credential`,
    {
      data: payload,
    }
  );
};

export const createClientCredentials = (payload: any) => {
  return POST(
    `api/v1/club/${payload?.clubId}/create-client-credential`,
    payload
  );
};

export const updateClientCredentials = (payload: any) => {
  return PUT(
    `api/v1/club/${payload?.clubId}/update-client-credential`,
    payload
  );
};
