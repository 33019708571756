<modal title="Delete User" :handler="modalVisible" :cssClass="'modal-md-2'" :footer="false"
    @modalHandler="cancelModal">
    <template v-slot:content>
        <div class="py-5 px-3">
            <p class="font-16 m-0 text-center">
                "{{payload.name}}" will loose access to club activities and reports.
                Are you sure you want to delete this user ?
            </p>
        </div>
            <dialog-actions-bar>
                <kbutton @click="cancelModal">Cancel</kbutton>
                <kbutton :theme-color="'primary'" @click="deleteHandler">Delete</kbutton>
            </dialog-actions-bar>
    </template>
</modal>