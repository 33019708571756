<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 12H2V2H9L8 1H2C1.44687 1 1 1.44687 1 2V12C1 12.5531 1.44687 13 2 13H4V12ZM15 6L12 3H6C5.44688 3 5 3.44688 5 4V14C5 14.5531 5.44688 15 6 15H14C14.5531 15 15 14.5531 15 14V6ZM14 14H6V4H11V7H14V14Z" fill="#24243A"/>
    </g>
    </svg>
</template>
<script>
    export default {
        name: "inputCopyIcon"
    }
</script>
<style scoped></style>