<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 8C2.38125 5.60938 5.0375 4 8 4C10.9625 4 13.6187 5.60938 15 8C13.6187 10.3906 10.9594 12 8 12C5.04063 12 2.38437 10.3906 1 8ZM8 7C8 6.44688 7.55312 6 7 6C6.44688 6 6 6.44688 6 7C6 7.55312 6.44688 8 7 8C7.55312 8 8 7.55312 8 7ZM2.18125 8C2.75625 7.175 3.58125 6.47813 4.45 5.96563C4.83125 5.74063 5.23438 5.55313 5.65 5.40625C4.94375 6.04688 4.5 6.97187 4.5 8C4.5 9.02812 4.94375 9.95312 5.65 10.5938C5.23438 10.4469 4.83125 10.2594 4.45 10.0344C3.58125 9.52188 2.75625 8.825 2.18125 8ZM10.35 10.5938C10.7656 10.4469 11.1688 10.2594 11.55 10.0344C12.4188 9.52188 13.2406 8.825 13.8188 8C13.2438 7.175 12.4188 6.47813 11.55 5.96563C11.1688 5.74063 10.7656 5.55313 10.35 5.40625C11.0563 6.04688 11.5 6.97187 11.5 8C11.5 9.02812 11.0563 9.95312 10.35 10.5938Z" fill="#24243A"/>
    </g>
    </svg>
</template>
<script>
    export default {
        name: "inputEyeIcon"
    }
</script>
<style scoped></style>