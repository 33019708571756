<template src="./accessControlWithTabs.html"></template>

<script>
import { TabStrip, TabStripTab } from "@progress/kendo-vue-layout";
import Breadcrumb from "@/components/common/AppBreadCrumbs/BreadCrumbs.vue";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar";
import AppLoader from "@/components/common/AppLoader/AppLoader";
import { Button } from "@progress/kendo-vue-buttons";
import userTabComponent from "./userTabComponent/userTabComponent.vue";
import roleTabComponent from "./roleTabComponent/roleTabComponent.vue";
import { Input } from "@progress/kendo-vue-inputs";

export default {
  components: {
    tabstrip: TabStrip,
    tabstripTab: TabStripTab,
    Breadcrumb,
    AppSnackBar,
    AppLoader,
    kbutton: Button,
    userTabComponent,
    roleTabComponent,
    "k-input": Input,
  },
  data() {
    return {
      clubId: this.$route.params.id,
      selected: 0,
      isPlatformAdmin: true,
      tabNames: [
        {
          name: "userTabComponent",
          value: "User",
          id: 1,
        },
        {
          name: "roleTabComponent",
          value: "Role",
          id: 2,
        },
        // {
        //     name: "menuSetupTabComponent",
        //     value: "Menu Setup",
        //     id: 3,
        // },
      ],
      tabNamesForUser: [
        {
          name: "userTabComponent",
          value: "User",
          id: 1,
        },
        {
          name: "roleTabComponent",
          value: "Role",
          id: 2,
        },
        // {
        //     name: "menuSetupTabComponent",
        //     value: "Menu Setup",
        //     id: 3,
        // },
      ],
      activeTab: "userTabComponent",
    };
  },
  computed: {
    club() {
      return this.$store.getters.clubDetails;
    },
    isClubUpdated() {
      return this.$store.state.club.showMsg;
    },
    showMsg() {
      return this.$store.state.club.status.Message;
    },
    clubUpdateLoading() {
      return this.$store.state.club.loading;
    },
  },
  created() {
    if (this.$route.query.accessControlTab) {
      this.activeTab = this.$route.query.accessControlTab;
    } else {
      this.updateActiveTabInQueryParams();
    }
  },
  mounted() {
    const clubInfo = JSON.parse(localStorage.getItem("clubInfo"));

    if ([1, 2].includes(clubInfo?.roleId)) {
      this.isPlatformAdmin = false;
    }

    if ([0, 99].includes(clubInfo?.roleId)) {
      this.isPlatformAdmin = true;
    }
  },

  setup() {},
  methods: {
    updateActiveTabInQueryParams() {
      this.$router.push({
        query: {
          ...this.$route.query,
          accessControlTab: this.activeTab,
        },
      });
    },
    switchTabHandlers(tabName) {
      this.activeTab = tabName;
      this.updateActiveTabInQueryParams();
    },
    onSelect: function (e) {
      this.selected = e.selected;
    },
    isClub() {
      return this.club.isAssociation ? "Association" : "Club";
    },
  },
};
</script>
