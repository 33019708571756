<template>
  <div>
    <modal
      :class="'max-height-100-145'"
      :title="fromUpdate ? 'Update Role' : 'Create Role'"
      :isLoading="addUserLoading"
      :handler="true"
      @modalHandler="this.modalHandler"
      :cssClass="'modal-xxl'"
    >
      <template v-slot:content>
        <div class="pt-0 px-2 pb-4">
          <div class="d-flex">
            <div class="w-370 max-w-100 mr-16">
              <div class="k-form-field text-start">
                <label>Role Name</label>
                <k-input
                  v-model="formData.name"
                  :placeholder="'Western'"
                  @input="() => (this.errorMessages.name = '')"
                />
                <div class="error-msg">{{ this.errorMessages.name }}</div>
              </div>
            </div>
            <div class="w-370 max-w-100 mr-16">
              <div class="k-form-field text-start">
                <label>Status</label>
                <dropdownlist
                  :data-items="this.statusItems"
                  :text-field="'text'"
                  :data-item-key="'id'"
                  :value="this.formData.status"
                  @change="this.handleStatusChangeValue"
                >
                </dropdownlist>
              </div>
            </div>
          </div>
          <div class="list-view">
            <grid
              :class="'mt-4 create-role-table'"
              :data-items="defaultRoles"
              :group="[]"
              :expand-field="'expanded'"
              @datastatechange="dataStateChange"
              :columns="defaultRoleColumns"
              :row-height="48"
              @expandchange="expandChange"
            >
              <template v-slot:nameCelltemplate="{ props }">
                <td v-if="!props.dataItem?.is_group">
                  <span v-if="props.dataItem?.fromThirdLevel">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.4583 16.0413L14.375 13.1247L11.4583 10.208"
                        stroke="#6C707D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.375 13.1247H9.375C7.53408 13.1247 6.04167 11.6323 6.04167 9.79134V3.95801"
                        stroke="#6C707D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  {{ props.dataItem?.text }}
                </td>
              </template>

              <template
                v-for="cell in celltemplates"
                :key="cell"
                v-slot:[cell.cell]="{ props }"
              >
                <td v-if="!props.dataItem?.is_group">
                  <checkbox
                    :disabled="!props.dataItem[cell.disabledKey]"
                    v-model="props.dataItem[cell.key]"
                    @change="handleCheckBoxChange(props.dataItem, cell.key)"
                  ></checkbox>
                </td>
              </template>
            </grid>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <div class="me-1">
            <kbutton @click="this.modalHandler" :class="''">Cancel</kbutton>
          </div>
          <div class="ms-1">
            <kbutton
              @click="onSubmitHandler"
              :theme-color="'primary'"
              :class="''"
            >
              {{ fromUpdate ? "Update" : "Create" }}</kbutton
            >
          </div>
        </div>
      </template>
    </modal>

    <app-loader v-if="isLoading" />
  </div>
</template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import modal from "@/components/common/AppModal/AppModal.vue";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { MaskedTextBox } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { getRolePermissionService } from "@/services/clubDetails/setting.service";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import { Grid } from "@progress/kendo-vue-grid";
import {
  createRolePermissionService,
  updateRolePermissionService,
} from "@/services/clubDetails/setting.service";

export default {
  props: {
    payload: {
      type: Object,
    },
    fromUpdate: {
      type: Boolean,
      default: false,
    },
    selectedItemId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    kbutton: Button,
    "app-loader": AppLoader,
    modal,
    numericTextBox: NumericTextBox,
    datePicker: DatePicker,
    "k-input": Input,
    dropdownlist: DropDownList,
    MaskedTextBox: MaskedTextBox,
    checkbox: Checkbox,
    AppSnackBar,
    grid: Grid,
  },
  data() {
    return {
      clubId: this.$route.params.id,
      errorMessages: {},
      clubRoleId: 0,
      isLoading: false,
      defaultRoles: [],
      formData: {
        name: "",
        status: {
          id: 1,
          text: "Active",
        },
      },
      statusItems: [
        // {
        //   id: 0,
        //   text: "Pending",
        // },
        {
          id: 1,
          text: "Active",
        },
        {
          id: 2,
          text: "Inactive",
        },
      ],
      celltemplates: [
        {
          cell: "createCellTemplate",
          key: "hasPostPermission",
          disabledKey: "isPermissionEnable",
        },
        {
          cell: "updateCellTemplate",
          key: "hasPutPermission",
          disabledKey: "isPermissionEnable",
        },
        {
          cell: "deleteCellTemplate",
          key: "hasDeletePermission",
          disabledKey: "isPermissionEnable",
        },
        {
          cell: "viewCellTemplate",
          key: "hasGetPermission",
          disabledKey: "isPermissionEnable",
        },
      ],
    };
  },
  mounted() {
    this.clubRoleId = this.selectedItemId || 0;
    this.getRoleInfo();
  },
  computed: {
    clubInfo() {
      return this.$store.state.club.club;
    },
    defaultRoleColumns() {
      return [
        {
          field: "name",
          title: "Feature",
          cell: "nameCelltemplate",
          width: "270px",
        },
        {
          title: "Create",
          cell: "createCellTemplate",
          width: "270px",
        },

        {
          title: "Update",
          cell: "updateCellTemplate",
          width: "270px",
        },
        {
          title: "Delete",
          cell: "deleteCellTemplate",
          width: "270px",
        },
        {
          title: "View",
          cell: "viewCellTemplate",
          width: "270px",
        },
      ];
    },
  },
  methods: {
    handleCheckBoxChange(dataItem, key) {
      let value = dataItem[key];

      this.defaultRoles.data.forEach((item) => {
        if (item?.items?.length > 0) {
          item.items.forEach((subItem) => {
            if (dataItem.systemMenuId == subItem.parentId) {
              subItem[key] = value;
              // if (!subItem?.isPermissionEnable) {
              //   subItem[key] = value;
              // }
            }
          });
        }
        if (item.text === dataItem.text) {
          item[key] = value;
        }
      });
    },
    expandChange(event) {
      event.dataItem[event.target.$props.expandField] = event.value;
    },
    getRoleInfo() {
      const payload = {
        clubId: this.clubId,
        clubRoleId: this.clubRoleId,
      };
      this.isLoading = true;
      getRolePermissionService(payload)
        .then((response) => {
          if (response.data) {
            this.defaultRoles = {
              data: this.parseDefaultRoleData(
                response.data?.data?.clubRolePermissionsList
              ),
            };
            this.formData.name = response.data?.data?.name;
            this.formData.status = this.statusItems.find(
              (item) =>
                item.id ==
                (response.data?.data?.status == 0
                  ? 1
                  : response.data?.data?.status)
            );
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    payloadGenerator() {
      let roles = [];

      this.defaultRoles.data.map((item) => {
        if (item?.items?.length > 0) {
          roles.push(...item.items);
        }
      });

      let payload = {
        clubId: this.clubId,
        clubRoleId: this.clubRoleId,
        name: this.formData.name,
        code: this.convertString(this.formData.name),
        status: this.formData.status.id,
        clubRolePermissionsList: roles,
      };

      return payload;
    },
    convertString(str) {
      if (str?.length === 0) return str;
      const words = str?.split(" ");
      const firstWord = words[0].toLowerCase();
      const remainingWords = words
        .slice(1)
        .map((word) => word.toLowerCase())
        .join("_");
      const convertedString = firstWord + "_" + remainingWords;
      return convertedString;
    },

    modalHandler() {
      this.$emit("close", false);
    },
    handleStatusChangeValue(e) {
      this.formData.status = e.value;
    },
    removeKeys(obj, keysToRemove) {
      const newObj = { ...obj };
      for (const key of keysToRemove) {
        if (newObj.hasOwnProperty(key)) {
          delete newObj[key];
        }
      }
      return newObj;
    },
    handleValidation() {
      let isValid = true;
      this.errorMessages = {};
      if (!this.formData.name) {
        this.errorMessages.name = "Role name is required";
        isValid = false;
      }
      return isValid;
    },
    onSubmitHandler() {
      if (this.handleValidation()) {
        if (this.fromUpdate) {
          this.updateRolePermission();
        } else {
          this.createRolePermission();
        }
      }
    },
    updateRolePermission() {
      let payload = this.payloadGenerator();
      this.isLoading = true;

      updateRolePermissionService(payload)
        .then((response) => {
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Role updated successfully.",
            type: "success",
          });
          if (this.clubInfo?.roleId == this.clubRoleId) {
            this.$emit("close", false, false);
            this.$store.dispatch("getMenuWithPermission", {
              clubId: this.clubInfo.id,
              clubRoleId: this.clubInfo.roleId,
            });
          } else {
            this.$emit("close", false, true);
          }
        })
        .catch((error) => {
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Something went wrong. Please try again later.",
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    createRolePermission() {
      let payload = this.payloadGenerator();
      this.isLoading = true;

      createRolePermissionService(payload)
        .then((response) => {
          this.$emit("close", false, true);
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Role created successfully.",
            type: "success",
          });
        })
        .catch((error) => {
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Something went wrong. Please try again later.",
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    parseDefaultRoleData(items) {
      let result = [];
      items.forEach((item) => {
        let allItems = [];

        if (["members", "dashboard", "emailLog"].includes(item.code)) {
          allItems.push(this.removeKeys(item, ["items"]));
        }

        if (item?.items?.length > 0) {
          item?.items.forEach((subItem) => {
            allItems.push(this.removeKeys(subItem, ["items"]));
            if (subItem?.items?.length > 0) {
              subItem?.items.forEach((subSubItem) => {
                let data = {
                  ...this.removeKeys(subSubItem, ["items"]),
                  fromThirdLevel: true,
                };
                allItems.push(data);
              });
            }
          });
        }
        let obj = {
          name: item?.text,
          is_group: true,
          value: item?.text,
          items: allItems,
        };
        result.push(obj);
      });
      return result;
    },
  },
};
</script>

<style lang="scss">
@import "./createAndEditRoleModal.scss";
</style>
