<template src="./FeatureControl.html"></template>

<script>
import { Switch } from "@progress/kendo-vue-inputs";
import { Form } from "@progress/kendo-vue-form";
import { Button } from "@progress/kendo-vue-buttons";
import AppSnackBar from "../../../common/AppSnackBar/AppSnackBar";
import AppLoader from "../../../common/AppLoader/AppLoader";
import { updateClubService } from "../../../../services/clubDetails/club.service";
import club from "@/store/clubDetails/club";

export default {
  components: {
    "k-switch": Switch,
    "k-form": Form,
    kbutton: Button,
    AppSnackBar,
    AppLoader,
  },
  data() {
    var clubId = this.$route.params.id;
    return {
      clubId,
      featureControl: {
        isDigitalPassChecked: false,
      },
      notifyMessage: "Success",
      notifyType: "success",
      isNotify: false,
      loader: false,
      changeEnablePass: "",
      changeEnableVoucher: "",
      changeEnableEmailCampaign: "",
    };
  },
  computed: {
    club() {
      return this.$store.getters.clubDetails;
    },
  },
  mounted() {
    this.getClub({
      clubId: this.clubId,
      loadMenu: false,
    });
    this.changeEnablePass = this.club.enablePass;
    this.changeEnableVoucher = this.club.enableVoucher;
    this.changeEnableEmailCampaign = this?.club?.enableCampaign;
  },
  methods: {
    updateClubCancel() {
      this.$router.push(`/club-details/${this.clubId}/dashboard`);
    },
    digitalPassStatusChange(e) {
      this.changeEnablePass = e.target.value;
    },
    voucherStatusChange(e) {
      this.changeEnableVoucher = e.target.value;
    },
    emailCampaignStatusChange(e) {
      this.changeEnableEmailCampaign = e.target.value;
    },
    getClub(clubId) {
      this.$store.dispatch("getClub", clubId);
    },
    save() {
      this.loader = true;
      this.club.logoId = this.club.logoId
        ? this.club.logoId
        : this.club.logo?.id;
      this.club.enablePass = this.changeEnablePass;
      this.club.enableVoucher = this.changeEnableVoucher;
      this.club.enableCampaign = this.changeEnableEmailCampaign;

      updateClubService(club.state.club)
        .then(() => {
          this.$store.dispatch("getClub", {
            clubId: this.$route.params.id,
            loadMenu: false,
          });
          this.showNotification(
            "Changes in " +
              this.isClub().toString().toLowerCase().trim() +
              " settings have been updated successfully.",
            "success"
          );
          this.loader = false;
        })
        .catch((error) => {
          this.loader = false;
        });
    },
    isClub() {
      return this.club.isAssociation ? "Association" : "Club";
    },

    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
  },
};
</script>
