<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13 3H10V2C10 1.45 9.55 1 9 1H6C5.45 1 5 1.45 5 2V3H2V5H3V14C3 14.55 3.45 15 4 15H11C11.55 15 12 14.55 12 14V5H13V3ZM6 3C6.00312 2.99688 6.00313 2.00311 6.00003 2H8.99687L9 2.00312V3H6.00313H6ZM4.00312 14H11V5H10V13H9V5H8V13H7V5H6V13H5V5H4V13.9969L4.00312 14Z" fill="#24243A"/>
    </svg>  
</template>
<script>
    export default {
        name: "deleteSmall_1"
    }
</script>
<style scoped></style>