<template>
  <div>
    <AppLoader v-if="isLoading"></AppLoader>
    <div>
      <Grid
        ref="grid"
        :class="'access-control-role-table-wrap mb-5'"
        :data-items="this.roleList"
        :take="take"
        :skip="skip"
        :total="total"
        :pageable="gridPageable"
        :columns="columns"
        @datastatechange="dataStateChange"
      >
        <GridNoRecords> No Data found. </GridNoRecords>

        <template v-slot:statusCell="{ props }">
          <td>
            <span>
              <span
                class="dot my-0 ms-0 h-10 w-10"
                :class="statusIcons[props.dataItem.status || 0]"
              ></span>
              {{ statuses[props.dataItem.status || 0] }}
            </span>
          </td>
        </template>

        <template v-slot:actions="{ props }">
          <td>
            <AppPopUp
              :buttonClass="'btn-clear'"
              :class="'btn-dot-light'"
              :items="actionItems[props.dataItem.status]"
              @dropId="(e) => actionHandler(e, props.dataItem)"
              :icon="'more-vertical'"
              :popUpSettings="popupSettings"
              :disabled="
                props.dataItem?.id == clubRoleId || props.dataItem?.isDefault
              "
            >
            </AppPopUp>
          </td>
        </template>

        <toolbar>
          <div class="d-flex flex-wrap justify-content-between w-100">
            <div
              class="search-box-wrap width-220 w-100-vsm me-3 mr-0-vsm mb-2 mb-sm-0"
            >
              <!-- <span class="k-icon k-i-zoom"></span>
              <span class="k-textbox k-grid-search k-display-flex">
                <k-input
                  :class="'mb-0 width-220 w-100-vsm'"
                  placeholder="Search"
                  @input="debounceSearch"
                >
                </k-input>
              </span> -->
            </div>

            <div class="w-100-vsm">
              <kbutton
                :size="'medium'"
                @click="toggleCreateOrEditRoleModalVisibility(true)"
                :theme-color="'primary'"
                :class="'w-100-vsm'"
                :disabled="this.hasPostPermission() == false"
                >Create Role
              </kbutton>
            </div>
          </div>
        </toolbar>
      </Grid>
    </div>
    <createAndEditRoleModal
      v-if="createOrEditRoleModalVisibility"
      :fromUpdate="fromUpdate"
      :selectedItemId="selectedItem?.id"
      @close="toggleCreateOrEditRoleModalVisibility"
    />
  </div>
  <template v-for="key in Object.keys(this.warningModals)" :key="key">
    <WarningModal
      v-if="warningModals[key].visibility"
      :modalDetails="warningModals[key]"
      :onCancel="() => onCancelWarningModal(key)"
      :onConfirm="warningModals[key].onConfirm"
    />
  </template>
</template>

<script>
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import AppPopUp from "@/components/common/AppPopUp/AppPopUp.vue";
import {
  getRoleListService,
  deleteRoleService,
  updateRoleStatusService,
} from "@/services/clubDetails/setting.service";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import WarningModal from "@/components/clubDetails/emailCampaign/warningModal/WarningModal.vue";
import createAndEditRoleModal from "./createAndEditRoleModal/createAndEditRoleModal.vue";
import { messages } from "@progress/kendo-vue-upload";

export default {
  inject: [
    "hasPutPermission",
    "hasDeletePermission",
    "hasPostPermission",
    "hasGetPermission",
  ],
  name: "RoleTabComponent",
  components: {
    AppSnackBar,
    AppLoader,
    Grid: Grid,
    toolbar: GridToolbar,
    GridNoRecords,
    AppPopUp,
    kbutton: Button,
    "k-input": Input,
    createAndEditRoleModal,
    WarningModal,
  },
  data() {
    return {
      warningModals: {
        deleteModal: {
          visibility: false,
          title: "Delete Role",
          message: "Are you sure you want to delete this role?",
          confirmText: "Delete",
          cancelText: "Cancel",
          onConfirm: this.deleteRole,
        },
        statusModal: {
          visibility: false,
          title: "Change Status",
          message: "Are you sure you want to change the status of this role?",
          confirmText: "Change",
          cancelText: "Cancel",
          onConfirm: this.onStatusChange,
        },
      },
      selectedItem: null,
      fromUpdate: false,
      isLoading: false,
      clubId: this.$route.params.id,
      roleList: [],
      statuses: {
        0: "Pending",
        1: "Active",
        2: "Inactive",
      },
      statusIcons: {
        0: "yellow",
        1: "green",
        2: "red",
      },
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      actionItems: {
        0: [
          ...(!this.hasPutPermission()
            ? []
            : [
                {
                  text: "Edit",
                  dropId: 1,
                },
              ]),
          ...(!this.hasDeletePermission()
            ? []
            : [
                {
                  text: "Remove",
                  dropId: 2,
                },
              ]),
        ],
        1: [
          ...(!this.hasPutPermission()
            ? []
            : [
                {
                  text: "Edit",
                  dropId: 1,
                },
                {
                  text: "Make Inactive",
                  dropId: 3,
                },
              ]),
          ...(!this.hasDeletePermission()
            ? []
            : [
                {
                  text: "Remove",
                  dropId: 2,
                },
              ]),
        ],
        2: [
          ...(!this.hasPutPermission()
            ? []
            : [
                {
                  text: "Edit",
                  dropId: 1,
                },
                {
                  text: "Make Active",
                  dropId: 4,
                },
              ]),
          ...(!this.hasDeletePermission()
            ? []
            : [
                {
                  text: "Remove",
                  dropId: 2,
                },
              ]),
        ],
      },

      createOrEditRoleModalVisibility: false,
    };
  },
  mounted() {
    this.getRoleList();
  },
  computed: {
    columns() {
      return [
        {
          field: "name",
          title: "Name",
          width: "300px",
        },
        {
          field: "memberCount",
          title: "Number Of Users",
          width: "300px",
        },
        {
          field: "status",
          title: "Status",
          cell: "statusCell",
          width: "300px",
        },
        {
          field: "",
          title: "",
          cell: "actions",
        },
      ];
    },
    clubRoleId() {
      return this.$store.state.club.club?.roleId;
    },
    isPlatformAdminFromUser() {
      return this.$store.state.user?.user?.isPlatformAdmin;
    },
  },
  methods: {
    onCancelWarningModal(key) {
      this.warningModals[key].visibility = false;
    },
    onOpenWarningModal(key) {
      this.warningModals[key].visibility = true;
    },
    actionHandler(type, item) {
      this.selectedItem = item;

      let messeges = {
        1: "Default role can not be Edited.",
        2: "Default role can not be Removed.",
        3: "Default role can not be Inactive.",
        4: "Default role can not be Active.",
      };

      if (item?.isDefault) {
        this.$store.commit("SHOW_NOTIFICATION", {
          message:
            messeges[type] || "Something went wrong. Please try again later.",
          type: "error",
        });
        return;
      }

      if (type == 1) {
        this.fromUpdate = true;
        this.toggleCreateOrEditRoleModalVisibility(true);
      }
      if (type == 2) {
        if (item?.memberCount > 0) {
          this.$store.commit("SHOW_NOTIFICATION", {
            message: `The role has ${item?.memberCount} associated users. Before deleting the role, please ensure you either change the role of these users or remove them.`,
            type: "warning",
          });
          return;
        } else {
          this.warningModals.deleteModal.message =
            "Are you sure you want to delete this role? The role currently has no associated users. You may proceed with deleting the role.";
        }

        this.onOpenWarningModal("deleteModal");
      }
      if (type == 3) {
        this.onOpenWarningModal("statusModal");
      }
      if (type == 4) {
        this.onOpenWarningModal("statusModal");
      }
    },
    closeeAllModal() {
      for (let key in this.warningModals) {
        this.warningModals[key].visibility = false;
      }
    },
    onStatusChange() {
      this.isLoading = true;
      const payload = {
        ...this.selectedItem,
        status: this.selectedItem.status == 1 ? 2 : 1,
      };
      updateRoleStatusService(payload)
        .then((res) => {
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Status changed successfully.",
            type: "success",
          });
          this.getRoleList();
        })
        .catch((err) => {
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Something went wrong. Please try again later.",
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.closeeAllModal();
        });
    },
    deleteRole() {
      this.isLoading = true;
      const payload = {
        clubId: this.clubId,
        id: this.selectedItem.id,
      };
      deleteRoleService(payload)
        .then((res) => {
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Role deleted successfully.",
            type: "success",
          });
          this.getRoleList();
        })
        .catch((err) => {
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Something went wrong. Please try again later.",
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.closeeAllModal();
        });
    },
    toggleCreateOrEditRoleModalVisibility(value, reload = false) {
      this.createOrEditRoleModalVisibility = value;
      if (!value) {
        this.fromUpdate = false;
        this.selectedItem = null;
      }

      if (reload) {
        this.getRoleList();
      }
    },
    getRoleList() {
      this.isLoading = true;
      const payload = {
        clubId: this.clubId,
        offset: 0,
        limit: 100,
      };

      getRoleListService(payload)
        .then((res) => {
          this.roleList = res.data?.data;
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "./roleTabComponent.scss";
</style>
