<template>
  <div>
    <AppLoader v-if="isLoading || userDataLoading"></AppLoader>
    <div>
      <Grid
        ref="grid"
        :class="'access-control-user-table-wrap mb-5'"
        :data-items="usersData.items"
        :take="take"
        :skip="skip"
        :total="total"
        :pageable="gridPageable"
        :columns="columns"
        @datastatechange="dataStateChange"
      >
        <GridNoRecords> No User(s) Found. </GridNoRecords>

        <template v-slot:roleCell="{ props }">
          <td>
            {{ props.dataItem.role.name }}
          </td>
        </template>

        <template v-slot:statusCell="{ props }">
          <td>
            <span>
              <span
                class="dot my-0 ms-0 h-10 w-10"
                :class="statusIcons[props.dataItem.userStatus || 0]"
              ></span>
              {{ statuses[props.dataItem.userStatus || 0] }}
            </span>
          </td>
        </template>

        <template v-slot:actions="{ props }">
          <td>
            <AppPopUp
              :buttonClass="'btn-clear'"
              :class="'btn-dot-light'"
              :items="actionItems[props.dataItem.userStatus || 0]"
              @dropId="(e) => actionHandler(e, props.dataItem)"
              :icon="'more-vertical'"
              :popUpSettings="popupSettings"
              :disabled="loggedUser?.id == props.dataItem.id"
            >
            </AppPopUp>
          </td>
        </template>

        <toolbar>
          <div class="d-flex flex-wrap justify-content-between w-100">
            <div
              class="search-box-wrap width-220 w-100-vsm me-3 mr-0-vsm mb-2 mb-sm-0"
            >
              <span class="k-icon k-i-zoom"></span>
              <span class="k-textbox k-grid-search k-display-flex">
                <k-input
                  :class="'mb-0 width-220 w-100-vsm'"
                  placeholder="Search"
                  @input="debounceSearch"
                >
                </k-input>
              </span>
            </div>

            <div class="w-100-vsm">
              <kbutton
                :size="'medium'"
                :disabled="this.hasPostPermission() == false"
                @click="
                  handleSendInvitationModalVisibility(
                    true,
                    'sendInvitationModalVisibility'
                  )
                "
                :theme-color="'primary'"
                :class="'w-100-vsm'"
                >Send Invitation
              </kbutton>
            </div>
          </div>
        </toolbar>
      </Grid>
    </div>
  </div>

  <sendInvitationModal
    v-if="sendInvitationModalVisibility"
    @close="
      handleSendInvitationModalVisibility(
        false,
        'sendInvitationModalVisibility'
      )
    "
    @onConfirm="onConfirmSendInvitation"
  />

  <sendInvitationModal
    v-if="changeRoleModalVisibility"
    :user="selectedUser"
    :fromUpdate="true"
    @close="
      handleSendInvitationModalVisibility(false, 'changeRoleModalVisibility')
    "
    @onConfirm="onChangeRole"
  />

  <template v-for="key in Object.keys(this.warningModals)" :key="key">
    <WarningModal
      v-if="warningModals[key].visibility"
      :modalDetails="warningModals[key]"
      :onCancel="() => onCancelWarningModal(key)"
      :onConfirm="warningModals[key].onConfirm"
    />
  </template>
</template>

<script>
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import AppPopUp from "@/components/common/AppPopUp/AppPopUp.vue";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import sendInvitationModal from "./sendInvitationModal/sendInvitationModal.vue";
import WarningModal from "@/components/clubDetails/emailCampaign/warningModal/WarningModal.vue";
import {
  inviteRoleMembersService,
  deleteUserService,
  userResendInvitationService,
  userChangeRoleService,
  userChangeStatusService,
  userCancelInvitationService,
} from "@/services/clubDetails/setting.service";

export default {
  inject: [
    "hasPutPermission",
    "hasDeletePermission",
    "hasPostPermission",
    "hasGetPermission",
  ],
  name: "UserTabComponent",
  components: {
    AppSnackBar,
    AppLoader,
    Grid: Grid,
    toolbar: GridToolbar,
    GridNoRecords,
    AppPopUp,
    kbutton: Button,
    "k-input": Input,
    sendInvitationModal,
    WarningModal,
  },
  data() {
    return {
      clubId: this.$route.params.id,
      selectedUser: null,
      searchText: "",
      warningModals: {
        deleteModal: {
          visibility: false,
          title: "Remove Confirmation",
          message:
            "Are you sure you want to remove this user from the system? This action cannot be undone.",
          confirmText: "Delete",
          cancelText: "Cancel",
          onConfirm: this.onDeleteUser,
        },
        statusModal: {
          visibility: false,
          title: "Change Status",
          message: "Are you sure you want to change the status of this user?",
          confirmText: "Change",
          cancelText: "Cancel",
          onConfirm: this.onStatusChange,
        },
        resendInvitationModal: {
          visibility: false,
          title: "Resend Invitation",
          message:
            "Are you sure you want to resend the invitation to this user?",
          confirmText: "Resend",
          cancelText: "Cancel",
          onConfirm: this.onResendInvitation,
        },
        cancelInvitationModal: {
          visibility: false,
          title: "Cancel Invitation",
          message:
            "Are you sure you want to cancel the invitation to this user?",
          confirmText: "Cancel",
          cancelText: "Cancel",
          onConfirm: this.onCancelInvitation,
        },
      },
      isLoading: false,
      statuses: {
        0: "Pending",
        1: "Active",
        2: "Inactive",
      },
      statusIcons: {
        0: "yellow",
        1: "green",
        2: "red",
      },
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      actionItems: {
        0: [
          ...(!this.hasPutPermission()
            ? []
            : [
                {
                  text: "Change Role",
                  dropId: 1,
                },
                {
                  text: "Resend Invitation",
                  dropId: 4,
                },
                {
                  text: "Cancel Invitation",
                  dropId: 6,
                },
              ]),
          ...(!this.hasDeletePermission()
            ? []
            : [
                {
                  text: "Delete",
                  dropId: 5,
                },
              ]),
        ],
        1: [
          ...(!this.hasPutPermission()
            ? []
            : [
                {
                  text: "Change Role",
                  dropId: 1,
                },
                {
                  text: "Make the user inactive",
                  dropId: 2,
                },
              ]),
          ...(!this.hasDeletePermission()
            ? []
            : [
                {
                  text: "Delete",
                  dropId: 5,
                },
              ]),
        ],
        2: [
          ...(!this.hasPutPermission()
            ? []
            : [
                {
                  text: "Change Role",
                  dropId: 1,
                },
                {
                  text: "Make the user active",
                  dropId: 3,
                },
              ]),
          ...(!this.hasDeletePermission()
            ? []
            : [
                {
                  text: "Delete",
                  dropId: 5,
                },
              ]),
        ],
      },
      sendInvitationModalVisibility: false,
      changeRoleModalVisibility: false,
    };
  },
  mounted() {
    const payload = {
      clubId: this.clubId,
      offset: 0,
      limit: 100,
    };
    this.$store.dispatch("getRolesList", payload);
    this.$store.dispatch("getUsersList", payload);
  },
  computed: {
    loggedUser() {
      return this.$store.state.user?.user;
    },
    userDetails() {
      return this.$store.getters.userDetails;
    },
    usersData() {
      return this.$store.state.systemDashboard.usersData;
    },
    total() {
      return this.usersData ? this.usersData.size : 0;
    },
    take() {
      return this.usersData ? this.usersData.limit : 0;
    },
    skip() {
      return this.usersData ? this.usersData.offset : 0;
    },
    isShowMsg() {
      return this.$store.state.voucherManageRecipients.showMsg;
    },
    showMsg() {
      return this.$store.state.voucherManageRecipients.status;
    },
    addUserModalVisibility() {
      return this.$store.state.systemDashboard.addUserModalVisibility;
    },
    userDataLoading() {
      return this.$store.state.systemDashboard.userDataLoading;
    },
    deleteUserLoading() {
      return this.$store.state.systemDashboard.deleteUserLoading;
    },
    changeRoleLoading() {
      return this.$store.state.systemDashboard.updateRoleLoading;
    },
    deleteRoleMemberPopUpVisibility() {
      return this.$store.state.systemDashboard.deleteRoleMemberPopUpVisibility;
    },
    changeRoleWarningPopUpVisibility() {
      return this.$store.state.systemDashboard.changeRolePopUpVisibility;
    },
    columns() {
      return [
        // {
        //   field: "name",
        //   title: "Name",
        // },
        {
          field: "email",
          title: "Email",
          width: "300px",
        },
        {
          field: "role",
          title: "Role",
          cell: "roleCell",
          width: "300px",
        },
        {
          field: "status",
          title: "Status",
          cell: "statusCell",
          width: "300px",
        },
        {
          field: "",
          title: "",
          cell: "actions",
        },
      ];
    },
  },
  methods: {
    onCancelWarningModal(key) {
      this.warningModals[key].visibility = false;
    },
    onOpenWarningModal(key) {
      this.warningModals[key].visibility = true;
    },
    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.onSearch(event);
      }, 600);
    },
    onSearch(e) {
      this.searchText = e.value;
      this.isSearching = true;
      if (this.searchText.length === 0) {
        setTimeout(() => {
          this.isSearching = false;
        }, 2000);
      }
      let payload = {
        clubId: this.clubId,
        searchText: this.searchText,
      };
      this.$store.dispatch("getUsersList", payload);
    },
    actionHandler(id, selectedUser) {
      this.selectedUser = selectedUser;

      if (id === 1) {
        this.handleSendInvitationModalVisibility(
          true,
          "changeRoleModalVisibility"
        );
      }
      if (id === 2) {
        this.onOpenWarningModal("statusModal");
      }
      if (id === 3) {
        this.onOpenWarningModal("statusModal");
      }
      if (id === 4) {
        this.onOpenWarningModal("resendInvitationModal");
      }
      if (id === 5) {
        this.onOpenWarningModal("deleteModal");
      }
      if (id === 6) {
        this.onOpenWarningModal("cancelInvitationModal");
      }
    },
    handleSendInvitationModalVisibility(value, type) {
      this[type] = value;
      if (!value) {
        this.selectedUser = null;
      }
    },
    onChangeRole(payload) {
      this.isLoading = true;
      payload.clubId = this.clubId;
      delete payload.email;
      userChangeRoleService(payload)
        .then((res) => {
          this.reloadUsersList();
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Role changed successfully.",
            type: "success",
          });
        })
        .catch((err) => {
          this.$store.commit("SHOW_NOTIFICATION", {
            message:
              err.response?.data.Status.Message ||
              "Something went wrong, please try again later.",
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onConfirmSendInvitation(payload) {
      this.isLoading = true;
      payload.clubId = this.clubId;
      inviteRoleMembersService(payload)
        .then((res) => {
          this.reloadUsersList();
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Invitation sent successfully.",
            type: "success",
          });
        })
        .catch((err) => {
          this.$store.commit("SHOW_NOTIFICATION", {
            message:
              err.response?.data.Status.Message ||
              "Something went wrong, please try again later.",
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onCancelInvitation() {
      this.closeeAllModal();
      this.isLoading = true;
      userCancelInvitationService({
        clubId: this.clubId,
        clubSystemUserId: this.selectedUser?.clubSystemUserId,
      })
        .then((res) => {
          this.reloadUsersList();
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Invitation cancelled successfully.",
            type: "success",
          });
        })
        .catch((err) => {
          this.$store.commit("SHOW_NOTIFICATION", {
            message:
              err.response?.data.Status.Message ||
              "Something went wrong, please try again later.",
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onDeleteUser() {
      this.closeeAllModal();
      this.isLoading = true;
      deleteUserService({
        clubId: this.clubId,
        clubSystemUserId: this.selectedUser?.clubSystemUserId,
      })
        .then((res) => {
          this.reloadUsersList();
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "User deleted successfully.",
            type: "success",
          });
        })
        .catch((err) => {
          this.$store.commit("SHOW_NOTIFICATION", {
            message:
              err.response?.data.Status.Message ||
              "Something went wrong, please try again later.",
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onResendInvitation() {
      this.closeeAllModal();
      this.isLoading = true;
      userResendInvitationService({
        clubId: this.clubId,
        clubSystemUserId: this.selectedUser?.clubSystemUserId,
      })
        .then((res) => {
          this.reloadUsersList();
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Invitation resent successfully.",
            type: "success",
          });
        })
        .catch((err) => {
          this.$store.commit("SHOW_NOTIFICATION", {
            message:
              err.response?.data.Status.Message ||
              "Something went wrong, please try again later.",
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onStatusChange() {
      this.closeeAllModal();
      this.isLoading = true;
      userChangeStatusService({
        clubId: this.clubId,
        clubSystemUserId: this.selectedUser?.clubSystemUserId,
        userStatus:
          this.selectedUser?.userStatus == 1
            ? 2
            : this.selectedUser?.userStatus == 2
            ? 1
            : 1,
      })
        .then((res) => {
          this.reloadUsersList();
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Status changed successfully.",
            type: "success",
          });
        })
        .catch((err) => {
          this.$store.commit("SHOW_NOTIFICATION", {
            message:
              err.response?.data.Status.Message ||
              "Something went wrong, please try again later.",
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    closeeAllModal() {
      for (let key in this.warningModals) {
        this.warningModals[key].visibility = false;
      }
    },
    reloadUsersList() {
      this.$store.dispatch("getUsersList", {
        clubId: this.clubId,
        offset: 0,
        limit: 100,
        searchText: this.searchText,
      });
    },
  },
};
</script>

<style lang="scss">
@import "./userTabComponent.scss";
</style>
