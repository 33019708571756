<template>
  <div>
    <modal :class="'max-height-100-145'" :title="this.user?.id ? 'Change Role' : 'Send Invitation'"
      :isLoading="addUserLoading" :handler="true" @modalHandler="this.modalHandler" :cssClass="'modal-md-2'">
      <template v-slot:content>
        <div class="pt-0 px-2 pb-4">
          <div class="row">
            <p v-if="!this.fromUpdate" class="mb-24">
              Invite a new user by providing their email address and selecting
              the role you wish to assign
            </p>
            <div class="col-sm-12">
              <div class="k-form-field text-start">
                <label>Email</label>
                <k-input :class="{ 'pointer-events-none': fromUpdate }" v-model="email"
                  :placeholder="'user@domain.com'" @input="() => (errorMessages.email = '')"
                  :disabled="fromUpdate" />

                <div class="error-msg">{{ errorMessages.email }}</div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="k-form-field text-start">
                <label>Role</label>
                <dropdownlist :class="'mb-24'" :data-items="rolesList" :text-field="'name'" :data-item-key="'id'"
                  :value="selectedRole" @change="handleRoleChange">
                </dropdownlist>
                <div class="error-msg">{{ errorMessages.role }}</div>
                <!-- <template v-for="item in roleItems" :key="item?.id">
                  <radiobutton
                    :value="item.id"
                    :checked="selectedRoleId == item.id"
                    @change="handleRoleChange"
                    :label="item.text"
                    :class="'mb-24'"
                  />
                </template> -->
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <div class="me-1">
            <kbutton @click="this.modalHandler" :class="''">Cancel</kbutton>
          </div>
          <div class="ms-1">
            <kbutton @click="this.onConfirm" :theme-color="'primary'" :class="''">
              {{ this.user?.id ? "Save" : "Invite" }}</kbutton>
          </div>
        </div>
      </template>
    </modal>

    <AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>
  </div>
</template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import modal from "@/components/common/AppModal/AppModal.vue";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { MaskedTextBox } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { RadioButton } from "@progress/kendo-vue-inputs";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
export default {
  props: {
    payload: {
      type: Object,
    },
    user: {
      type: Object,
    },
    fromUpdate: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    kbutton: Button,
    "app-loader": AppLoader,
    modal,
    numericTextBox: NumericTextBox,
    datePicker: DatePicker,
    "k-input": Input,
    dropdownlist: DropDownList,
    MaskedTextBox: MaskedTextBox,
    checkbox: Checkbox,
    AppSnackBar,
    radiobutton: RadioButton,
  },
  data() {
    return {
      clubId: this.$route.params.id,
      isChangeRole: false,
      notifyMessage: "Success",
      notifyType: "success",
      isNotify: false,
      selectedRoleId: 0,
      selectedRole: {
        id: 0,
        name: "Select Role",
      },
      email: "",
      errorMessages: {},
      roleItems: [
        {
          id: 1,
          text: "Admin Access",
        },
        {
          id: 2,
          text: "Viewer Access",
        },
        {
          id: 3,
          text: "Report",
        },
      ],
    };
  },
  mounted() {
    if (this.user?.id) {
      this.email = this.user.email;
      this.selectedRole = this.rolesList.find(
        (role) => role.id == this.user.role?.id
      );
      this.isChangeRole = true;
    }
  },
  computed: {
    rolesList() {
      return this.$store.state.common.rolesList;
    },
    isRoleListLoading() {
      return this.$store.state.common.isRoleListLoading;
    },
  },
  methods: {
    modalHandler() {
      this.$emit("close");
    },
    handleRoleChange(e) {
      this.selectedRole = e.value;
    },
    onConfirm() {
      if (this.validateData()) {
        this.modalHandler();
        this.$emit("onConfirm", {
          email: this.email,
          roleId: this.selectedRole?.id,
          ...(this.user?.id && { clubSystemUserId: this.user.clubSystemUserId })
        });
      }
    },
    validateData() {
      let isValid = true;
      this.errorMessages = {};
      if (!this.email) {
        isValid = false;
        this.errorMessages.email = "Please enter a valid email address";
      }
      if (this.selectedRole?.id == 0) {
        isValid = false;
        this.errorMessages.role = "Please select a role";
      }
      if (this.email) {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailPattern.test(this.email)) {
          isValid = false;
          this.errorMessages.email = "Please enter a valid email address";
        }
      }
      return isValid;
    },
  },
};
</script>

<style></style>
