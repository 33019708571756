<template src="./SettingComponent.html"></template>

<script>
import { TabStrip, TabStripTab } from "@progress/kendo-vue-layout";
import ClubSettings from "./clubSettings/ClubSettings.vue";
import FatureControl from "./featureControl/FeatureControl.vue";
import Breadcrumb from "../../common/AppBreadCrumbs/BreadCrumbs.vue";
import AppSnackBar from "../../common/AppSnackBar/AppSnackBar";
import AppLoader from "../../common/AppLoader/AppLoader";
import AccessControl from "./accessControl/AccessControl.vue";
import { Button } from "@progress/kendo-vue-buttons";
import Integrations from "./integrations/integrations.vue";
import accessControlWithTabs from "./accessControlWithTabs/accessControlWithTabs.vue";
import { computed } from "vue";

export default {
  components: {
    tabstrip: TabStrip,
    tabstripTab: TabStripTab,
    ClubSettings,
    FatureControl,
    AccessControl,
    accessControlWithTabs,
    Breadcrumb,
    AppSnackBar,
    AppLoader,
    kbutton: Button,
    Integrations,
  },
  data() {
    return {
      clubId: this.$route.params.id,
      selected: 0,
      isPlatformAdmin: false,
      tabNames: [
        {
          name: "ClubSettings",
          value: "Club Settings",
          id: 1,
        },
        {
          name: "FatureControl",
          value: "Feature Control",
          id: 2,
        },
        {
          name: "accessControlWithTabs",
          value: "Access Control",
          id: 3,
        },
        {
          name: "Integrations",
          value: "Integrations",
          id: 4,
        },
      ],
      tabNamesForUser: [
        {
          name: "ClubSettings",
          value: "Club Settings",
          id: 1,
        },
        {
          name: "accessControlWithTabs",
          value: "Access Control",
          id: 3,
        },
        {
          name: "Integrations",
          value: "Integrations",
          id: 4,
        },
      ],
      activeTab: "ClubSettings",
    };
  },
  computed: {
    club() {
      return this.$store.getters.clubDetails;
    },
    isClubUpdated() {
      return this.$store.state.club.showMsg;
    },
    showMsg() {
      return this.$store.state.club.status.Message;
    },
    clubUpdateLoading() {
      return this.$store.state.club.loading;
    },
    isPlatformAdminFromUser() {
      return this.$store.state.user?.user?.isPlatformAdmin || false;
    },
  },
  created() {
    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab;
    } else {
      this.updateActiveTabInQueryParams();
    }
  },
  watch: {
    isPlatformAdminFromUser: {
      handler: function (val) {
        this.isPlatformAdmin = val;
      },
      immediate: true,
    },
  },
  provide() {
    return {
      hasGetPermission: computed(() => this.hasGetPermission),
      hasPostPermission: computed(() => this.hasPostPermission),
      hasPutPermission: computed(() => this.hasPutPermission),
      hasDeletePermission: computed(() => this.hasDeletePermission),
    };
  },
  mounted() {
    const clubInfo = JSON.parse(localStorage.getItem("clubInfo"));

    // if ([1, 2].includes(clubInfo?.roleId)) {
    //   this.isPlatformAdmin = false;
    // }

    // if ([0, 99].includes(clubInfo?.roleId)) {
    //   this.isPlatformAdmin = true;
    // }
  },

  setup() {},
  methods: {
    hasDeletePermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: "dashboard",
        itemCode: null,
        childItemCode: null,
      });
    },
    hasPostPermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: "dashboard",
        itemCode: null,
        childItemCode: null,
      });
    },
    hasPutPermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: "dashboard",
        itemCode: null,
        childItemCode: null,
      });
    },
    hasDeletePermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: "dashboard",
        itemCode: null,
        childItemCode: null,
      });
    },
    returnIsPlatformAdmin() {
      return this.isPlatformAdmin;
    },
    updateActiveTabInQueryParams() {
      this.$router.push({
        query: {
          ...this.$route.query,
          tab: this.activeTab,
        },
      });
    },
    switchTabHandlers(tabName) {
      this.activeTab = tabName;
      this.updateActiveTabInQueryParams();
    },
    onSelect: function (e) {
      this.selected = e.selected;
    },
    isClub() {
      return this.club.isAssociation ? "Association" : "Club";
    },
  },
};
</script>
