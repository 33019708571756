<template src="./AddUserModal.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import modal from "@/components/common/AppModal/AppModal.vue";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { MaskedTextBox } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import { generatePassword, generatePasswordErrorMessage } from "@/utils/helper";
export default {
  props: {
    payload: {
      type: Object,
    },
  },
  components: {
    kbutton: Button,
    "app-loader": AppLoader,
    modal,
    numericTextBox: NumericTextBox,
    datePicker: DatePicker,
    "k-input": Input,
    dropdownlist: DropDownList,
    MaskedTextBox: MaskedTextBox,
    checkbox: Checkbox,
    AppSnackBar,
  },
  data() {
    return {
      clubId: this.$route.params.id,
      roleItems: [
        { id: 1, text: "Club Admin" },
        { id: 2, text: "Report Viewer" },
      ],
      localDataSourceForDigitalPass: [
        { text: "item1", value: 1 },
        { text: "item2", value: 2 },
      ],
      localDataSourceForVoucher: [
        { text: "item1", value: 1 },
        { text: "item2", value: 2 },
      ],
      roleValue: { id: 1, text: "Club Admin" },
      mask: "(+880) 0000-000000",
      // defaultValue: "(+880) 1309-990581",
      digitalPassCheckBoxStatus: false,
      voucherCheckBoxStatus: false,
      emailCampaignCheckBoxStatus: false,
      email: "",
      emailError: "",
      firstName: "",
      firstNameError: "",
      lastName: "",
      lastNameError: "",
      phoneNumber: "",
      passwordValue: "",
      passwordError: "",
      notifyMessage: "Success",
      notifyType: "success",
      isNotify: false,
    };
  },
  mounted() {},
  computed: {
    addUserErrors() {
      return this.$store.state.systemDashboard.addUserErrors;
    },
    addUserLoading() {
      return this.$store.state.systemDashboard.addUserLoading;
    },
  },
  methods: {
    clearEmailError() {
      if (this.addUserErrors.Email) {
        this.addUserErrors.Email[0] = "";
      }
      this.emailError = "";
    },
    generatePasswordHandler() {
      this.passwordError = "";
      this.passwordValue = generatePassword();
    },
    handleDigitalPassCheckBoxStatus(event) {
      this.digitalPassCheckBoxStatus = event.value;
    },
    handleVoucherCheckBoxStatus(event) {
      this.voucherCheckBoxStatus = event.value;
    },
    handleEmailCampaignCheckBoxStatus(event) {
      this.emailCampaignCheckBoxStatus = event.value;
    },
    modalHandler() {
      this.$store.commit("ADD_USER_MODAL_VISIBILITY", false);
      this.$store.commit("SET_ADD_USER_ERRORS", []);
    },
    handleRoleChangeValue(e) {
      this.roleValue = e.value;
    },
    dataValidation() {
      function onlyNumbers(str) {
        return /^[0-9.,]+$/.test(str);
      }

      function onlySpaces(str) {
        return /^\s*$/.test(str);
      }

      let returnValue = true;
      if (!this.email) {
        this.emailError = "Email is required";
        returnValue = false;
      }
      if (!this.passwordValue) {
        this.passwordError = "Password is required.";
        returnValue = false;
      }

      if (!this.firstName) {
        this.firstNameError = "First name is required.";
        returnValue = false;
      }

      if (!this.lastName) {
        this.lastNameError = "Last name is required.";
        returnValue = false;
      }

      if (onlySpaces(this.firstName)) {
        this.firstNameError = "First name format is invalid.";
        returnValue = false;
      }

      if (onlySpaces(this.lastName)) {
        this.lastNameError = "Last name format is invalid.";
        returnValue = false;
      }

      if (onlyNumbers(this.firstName)) {
        this.firstNameError = "First name format is invalid.";
        returnValue = false;
      }

      if (onlyNumbers(this.lastName)) {
        this.lastNameError = "Last name format is invalid.";
        returnValue = false;
      }

      if (this.firstName.length > 50) {
        this.firstNameError = "Enter less than 50 characters.";
        returnValue = false;
      }

      if (this.lastName.length > 50) {
        this.lastNameError = "Enter less than 50 characters.";
        returnValue = false;
      }

      if (this.email) {
        const emailValidation = () => {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(this.email).toLowerCase());
        };
        if (!emailValidation()) {
          this.emailError = "Please provide a valid email address.";
          returnValue = false;
        }
      }

      if (this.passwordValue) {
        let initial = "Password must contain";
        let messeges = [];
        let hasError = false;

        if (this.passwordValue.length < 8) {
          messeges.push("at least 8 characters");
          hasError = true;
          returnValue = false;
        }
        if (!this.passwordValue.match(/[a-z]/)) {
          returnValue = false;
          messeges.push("at least one lowercase letter");
          hasError = true;
        }
        if (!this.passwordValue.match(/[A-Z]/)) {
          returnValue = false;
          messeges.push("at least one uppercase letter");
          hasError = true;
        }
        if (!this.passwordValue.match(/[0-9]/)) {
          returnValue = false;
          messeges.push("at least one number");
          hasError = true;
        }
        if (
          !this.passwordValue.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>~\/?]/)
        ) {
          returnValue = false;
          messeges.push("at least one special character");
          hasError = true;
        }

        if (hasError) {
          this.passwordError = generatePasswordErrorMessage(initial, messeges);
        }

        if (onlySpaces(this.passwordValue)) {
          this.passwordError = "Invalid password.";
          returnValue = false;
        }
      }

      return returnValue;
    },
    addUserHandler() {
      if (this.dataValidation()) {
        const payload = {
          clubId: this.clubId,
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          password: this.passwordValue,
          roleId: this.roleValue.id,
        };
        this.$store.dispatch("addUser", payload);
        // this.$store.commit("ADD_USER_MODAL_VISIBILITY", false);
      }
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
  },
};
</script>

<style></style>
